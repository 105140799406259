<template>
    <section class="main subscription-list-content">
       <div class="row">
            <div class="col-lg-12"> 
                <div class="card p-0">
                    <div class="mobile-view col-md-12" v-bind:data-delay="1500">
                        <div
                            v-for="(item, index) in subscriptionHistory"
                            v-show="subscriptionHistory.length"
                            :key="`subscriptionHistory-${index}`"
                            class="mobile-entry"
                        >
                            <div>
                                <span>Date start: </span>{{ item.dt_start }}
                            </div>
                            <div>
                                <span>Date end: </span>{{ item.dt_end }}
                            </div>
                            <div>
                                <span>Description: </span>Geocell {{ item.type }} Service
                            </div>
                            <div>
                                <span>Number: </span>+{{ item.user.phone_code }} {{ item.user.phone_number }}
                            </div>
                            <div>
                                <span>Amount: </span>{{ item.payment.currency }} {{ minor2Major(item.payment.amount) }}
                            </div>
                            <div>
                                <span>Status: </span><span
                                  :class="{active: item.status === 'active',
                                           inactive: item.status === 'inactive',
                                           unsubscribed: item.status === 'unsubscribed'}"
                                >{{ getStatus(item) }}</span>
                            </div>
                            <div>
                                <span>Print: </span>
                                <a
                                    v-show="item.status !== 'pending'"
                                    :id="item.id"
                                    :href="getPrintInvoiceUrl(item.id)"
                                    target="_blank"
                                    :value="item.id"
                                >
                                    <i class="fa fa-print"/>
                                </a>
                            </div>
                            <div>
                                <span>Download: </span>
                                <a
                                  v-show="item.status !== 'pending'"
                                  :href="getDownloadInvoiceUrl(item.id)"
                                >
                                    <i class="fa fa-download"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <table class="table table-responsive-md">
                        <thead>
                            <th width="15%"> {{ $translate('date_start') }}</th>
                            <th width="15%"> {{ $translate('date_end') }}</th>
                            <th width="25%"> {{ $translate('description') }} </th>
                            <th width="20%">{{ $translate('number') }} </th>
                            <th width="5%"> {{ $translate('amount') }} </th>
                            <th width="10%"> {{ $translate('status') }} </th>
                            <th class="text-center"  width="5%"> {{ $translate('print') }}</th>
                            <th class="text-center" width="5%">  {{ $translate('download') }}</th>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in subscriptionHistory"
                                v-show="subscriptionHistory.length"
                                :key="`subscriptionHistory-${index}`"
                            >
                                <td><span>{{ item.dt_start }}</span></td>
                                <td><span>{{ item.dt_end }}</span></td>
                                <td><span>{{$config('app_name')}} Service</span></td>
                                <td><span>+{{ item.user.phone_code }} {{ item.user.phone_number }}</span></td>
                                <td class="green">
                                    <span>{{item.payment.currency }} {{ minor2Major(item.payment.amount) }}</span>
                                </td>
                                <td>
                                    <span
                                      :class="{active: item.status === 'active', inactive: item.status === 'inactive', unsubscribed: item.status === 'unsubscribed'}"
                                    >{{ getStatus(item) }}</span>
                                </td>
                                <td class="text-center">
                                    <a
                                        v-show="item.status !== 'pending'"
                                        :id="item.id"
                                        :href="getPrintInvoiceUrl(item.id)"
                                        target="_blank"
                                        :value="item.id"
                                    >
                                        <i class="fa fa-print"/>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a v-show="item.status !== 'pending'"  :href="getDownloadInvoiceUrl(item.id)">
                                        <i class="fa fa-download"/>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <teleport to="body">
            <unsubscribe-modal/>
        </teleport>
    </section>
</template>

<script>
    import UnsubscribeModal from '../../components/modals/UnsubscribeModal';  
    export default {
        components: {
            UnsubscribeModal
        },

        data: () => ({
            subscriptionHistory: [],
        }),

        mounted(){
            this.getHistory();
        },

        methods:{
            getHistory(){
                axios.get(`/subscriptions/user_subscriptions`)
                    .then((response) => {
                        this.subscriptionHistory = _.orderBy(response.data.data, 'dt_end', 'desc');
                    });
            },
            minor2Major(amount) {
                return (amount / 100).toFixed(2)
            },

            getStatus(item){
                return (item.status === 'unsubscribed' && new Date(item.dt_end) > new Date()) ? 'Active until '+ item.dt_end : item.status
            },

            getPrintInvoiceUrl(subscriptionId) {
               return `/user/printInvoice/${subscriptionId}`
            },

            printInvoice(subscriptionId) {
                this.printPage(`/user/printInvoice/${subscriptionId}`)
            },

            getDownloadInvoiceUrl(itemid) {
                return `/user/downloadInvoice/${itemid}`
            },
            closePrint() {
                document.body.removeChild(this.contentWindow);
            },
        }
    }
</script>

<style scoped>
    span.active {
        color: #27ca1d;
    }

    span.inactive {
        color: #aaaaaa;
    }

    span.unsubscribed {
        color: #f6993f;
    }

    .mobile-entry div span {
        font-weight: bold;
        padding: 10px;
    }

    .mobile-entry {
        padding-bottom: 10px;
    }

    .mobile-entry:not(:first-child) {
        margin-top: 10px;
    }

    .mobile-entry:not(:last-child) {
        border-bottom: 1px rgb(111, 108, 127) dashed;
    }

    @media (max-width: 800px) {
        .table {
            display: none;
        }

        .mobile-view {
            display: block;
        }
    }

    @media (min-width: 800px) {
        .table {
            display: table;
        }

        .mobile-view {
            display: none;
        }
    }
</style>

