<template>
    <div class="container cookie-policy-content page-content">
        <div v-html="$translate('cookie_policy_text')"></div>
    </div>
</template>

<script>
    export default{}
</script>

