<template>
        <GoogleMap 
            :api-key="$config('google_api_key')"
            style="width: 100%; min-height: 100%"
            :center="center" 
            :zoom="13"
            map-type-id="terrain"
            :mapTypeControl="false"
            ref="map"
            class="dashboard-map"
        >
            <InfoWindow 
                v-for="marker, index in markers" 
                :options="{ position: marker.position, content: marker.address, pixelOffset: {width: 0,height: -44} }" 
            />
            <Marker v-for="marker, index in markers" 
                :options="{ position: marker.position }" 
                :key="index"
                :draggable="false"
            />
        </GoogleMap>     
</template>

<script>
    import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";

    export default {
        components: {GoogleMap, Marker, InfoWindow},

        props: {
            location: {
                type: Object,
                default: () => {}
            }
        },

        data() {
            return {
                markers: [],
                center: { 
                    lat: 52.5196, lng: 13.4069
                },
                currentPlace: null, 
            }
        },
        watch: {
            location() {
                this.initMap()
            }
        },
        mounted(){
            this.initMap();
        },
        methods: {
            initMap() {
                this.markers = [];
                if (Array.isArray(this.location) && this.location.length > 0) {
                    this.location.forEach((location) => {
                        if (location.address === null) {
                            this.$geocoder.send(location.position, (res) => {
                                if (res.results.length > 0) {
                                    this.markers.push({
                                        position: {
                                            lat: location.position.lat,
                                            lng: location.position.lng,
                                        },
                                        address: res.results[0].formatted_address
                                    })
                                } 
                                this.updateCenter(this.markers[0].position)
                            });
                        } else {
                            this.markers.push({
                                position: {
                                    lat: location.position.lat,
                                    lng: location.position.lng,
                                },
                                address: location.address
                            })
                            this.updateCenter(this.markers[0].position)
                        }
                    });
                } else if (typeof this.location !== 'undefined' && typeof this.location.position !== 'undefined') {
                    if (this.location.address === null) {
                        this.$geocoder.send(this.location.position, (res) => {
                            if (res.results.length > 0) {
                                this.markers.push({
                                    position: {
                                        lat: this.location.position.lat,
                                        lng: this.location.position.lng,
                                    },
                                    address: res.results[0].formatted_address
                                })
                            } 
                            
                            this.updateCenter(this.markers[0].position)
                        });
                    } else {
                        this.markers.push({
                            position: {
                                lat: this.location.position.lat,
                                lng: this.location.position.lng,
                            },
                            address: this.location.address
                        });
                        this.updateCenter(this.markers[0].position)
                    }
                } else {
                    
                    if ( !this.geolocate() ) {
                        axios.get("/user/ip").then((res) => {
                            if (res.data.data.location) {
                                this.updateCenter({
                                    lat: res.data.data.location.latitude,
                                    lng: res.data.data.location.longitude
                                });

                            }
                        })
                    }
                }
            },
            geolocate: function() {
                navigator.geolocation.getCurrentPosition(position => {
                    this.updateCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    return true;
                }, err => { return false; });
            },

            updateCenter(position) {
                this.center.lat = position.lat
                this.center.lng = position.lng
                if(this.$refs.map.map){
                    this.$refs.map.map.panTo(this.center);
                }
            }
        }
    }
</script>