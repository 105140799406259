<template>
    <div>
        <component :is="layout">  
            <router-view v-slot="{ Component, route }">
                <transition name="page" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
        </component>
        <teleport to="body">
            <notification-modal/>
        </teleport>
    </div>
</template>

<script>
    import NotificationModal from './modals/NotificationModal';
    import {loadMessages} from '../plugins/i18n';

    const requireContext = require.context('~/public/layouts', false, /.*\.vue$/)

    const layouts = requireContext.keys()
        .map(file =>
            [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
        )
        .reduce((components, [name, component]) => {
            components[name] = component.default || component
            return components
        }, {})

    export default {
        components: {
            NotificationModal
        },

        data(){
            return {
                defaultLayout: 'default'
            };
        },

        mounted(){
      
        },

        watch: {
            '$store':{
                handler: function (value) {
                    localStorage.setItem('store', JSON.stringify(value)); 
                },
                deep: true 
            },
            '$store.locale': function(value){
                (async function () {
                    await loadMessages(value);
                })();
            },
            '$store.currency': function(value){
                this.$payment.update(value);
            }
        },    

        computed: {
            layout(){
                return layouts[this.$route.meta.layout] || layouts[this.defaultLayout];
            }
        }
    }
</script>
