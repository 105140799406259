<template>
    <modal-base 
        id="unsubscribe-modal" 
        dialogClass="notification-modal"
        :hideHeader="true"
    >
        <div v-html="$translate('you_want_subscribe')"></div>
        <template v-slot:footer>
            <button  class="btn btn-secondary" data-bs-dismiss="modal">
                No
            </button>
            <button  class="btn btn-primary" @click="unsubscribe">
                Yes
            </button>
        </template>
    </modal-base>    
</template>

<script>
    export default {
        mounted(){
            if(this.$route.query.unsubscribe){
                this.$modals.show('unsubscribe-modal');
            }
        },

        methods: {
            unsubscribe(){
                axios.get('/subscriptions/unsubscribe')
                    .then(res => {
                        if (res.data.result === 'fail') {
                            this.$modals.hide('unsubscribe-modal');
                            this.$modals.notification(res.data.message);
                            setTimeout(()=>{location = '/dashboard/subscriptionHistory'}, 1000);
                        }
                        if (res.data.result === 'success') {
                            this.$modals.hide('unsubscribe-modal');
                            this.$modals.notification(this.$translate('unsub_canceled'));
                            setTimeout(()=>{location = '/dashboard/subscriptionHistory'}, 1000);
                        } 
                    }).catch(error => {
                        this.$modals.hide('unsubscribe-modal');
                        this.$modals.notification('Unidentified error');
                        setTimeout(()=>{location = '/dashboard/subscriptionHistory'}, 1000);
                    })
            }
        }
    }
</script>
