<template>
    <modal-base 
        id="register-modal" 
        :title="$translate('create_account')"
        dialogClass="modal-dialog-centered"
        :hideFooter="true"
    >
        <div class="container">
            <Form @submit="onSubmit" ref="registerForm">
                <div class="mb-3">
                    <Field 
                        name="name"
                        class="form-control" 
                        :placeholder="$translate('enter_name_surname')"
                        rules="required"
                        v-model="name"
                    />
                    <ErrorMessage name="name" as="error"> </ErrorMessage>  
                </div>    
                <div class="mb-3">
                    <phone-code :isUpdateStore="false" @update:phone-code="updateCode"/>
                </div>
                <div class="mb-3">
                    <Field 
                        name="phone"
                        class="form-control" 
                        :placeholder="$translate('enter_phone_number')"
                        rules="required|numeric"
                        v-model="phone"
                    />
                    <ErrorMessage name="phone" as="error"> </ErrorMessage>  
                </div>   

                <div class="mb-3">
                    <Field 
                        name="email"
                        class="form-control" 
                        :placeholder="$translate('enter_email')"
                        rules="required|email"
                        v-model="email"
                    />
                    <ErrorMessage name="email" as="error"> </ErrorMessage>  
                </div>   

                <div class="mb-3">
                    <Field 
                        name="password"
                        class="form-control" 
                        :placeholder="$translate('enter_pw')"
                        rules="required"
                        v-model="password"
                        type="password"
                    />
                    <ErrorMessage name="password" as="error"></ErrorMessage>  
                </div>   
                <div class="mb-3">
                    <button class="btn btn-primary modal-submit">
                        {{ $translate('register') }}
                    </button>
                </div>
            </Form>
        </div>    
    </modal-base>    
</template>

<script>
    
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import PhoneCode from '../forms/PhoneCode';

    export default {
        components: {
            Field,
            Form,
            ErrorMessage,
            PhoneCode
        },

        data(){
            return {
                name: '',
                email: '',
                password: '',
                phone: '',
                phone_code: ''
            };
        },
        methods: {
            updateCode(code){
                this.phone_code = code;
            },
            
            onSubmit(e){
                axios.post('/user/register',{
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    phone_code: this.phone_code,
                    phone_number: this.phone,
                    currency: this.$store.currency
                })
                .then((data) => {
                    location = "/dashboard";
                }).catch((err) => {
                    this.$refs.registerForm.setErrors(err.response.data.data);
                });
            },

            resolveErrors(errors, message){
               // console.log(errors, message);
            }       
        }    
    }

</script>
