<template>
    <payment 
        ref="payment" 
        @register_error="registerError"
        @pending="pending"   
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        />    
        <teleport to="body">
            <IframeModal 
                url="/payments/iframes/sandbox"
                :data="iframeData"
            />
        </teleport>    
    </payment>
</template>

<script>

    import PaymentForm from '../../forms/PaymentForm';
    import IframeModal from '../../modals/IframeModal';
    import Payment from '../PaymentContainer';

    export default {
        components: {
            PaymentForm, Payment, IframeModal
        }, 
        data() {
            return {
                isProcessing: false,
                errors: [],
                data: {},
                iframeData: {}
            }
        },
        methods: {
            registerError(data){
                this.errors = data;    
            },

            submitForm(data){
                this.$refs.payment.processPayment(data);
                //this.iframeData = data;      
            },

            pending(data){
                this.iframeData = data.data;
            },
        }
    }
</script>

<style>
    #threeDSModal .modal-body {
      padding: 0 !important;
    }

    #threeDSModal .container {
      padding: 0 !important;
    }
</style>
