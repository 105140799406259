<template>
    <div>
        <div class="position-relative">   
            <div class="loader-wrapper" id="loader" v-if="isProcessing">
                <div class="loader"></div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isProcessing: false,
                isRegister: false,
                data: {},
                attemptRefrence: '',
                profilingReference: '',
            }
        },
        mounted() {
            this.loadNethone();
        },
        methods: {
            processPayment(data)
            {
                this.data = data;
                this.data.attempt_refrence = this.attemptRefrence;
                this.data.profiling_refrence = this.profilingReference;
                if(this.isProcessing){
                    return;
                }
                this.isProcessing = true;
                if (window.dftp){
                    window.dftp.profileCompleted().catch(err => console.error("Profiling failed with err: " + err)).finally((result) => {
                        console.log('Profiling success');
                        if(!this.$auth.isAuth() && !this.isRegister){
                            this.createPaymentWithRegister();
                            return;
                        }
                        this.createPayment();
                    });
                }else{
                    if(!this.$auth.isAuth() && !this.isRegister){
                        this.createPaymentWithRegister();
                        return;
                    }
                    this.createPayment();
                }
            },

            createPayment() {
                let prepareData = {
                    currency: this.$store.currency,
                    country: this.$store.phone_iso
                };
                let params = Object.assign(this.data, prepareData);
                axios.post('/payments/create', params)
                    .then((response) => {
                        this.isProcessing = false;
                        if(response.data.result == 'success'){
                            this.$payment.successPayment();
                            return;
                        }
                        if(response.data.result == 'pending'){
                            this.$emit('pending', response.data);
                            return;
                        }
                        if(response.data.result == 'fail'){
                            this.$payment.failPayment();
                        }
                    }).catch((response) => {
                        this.isProcessing = false;
                        this.$emit('fail', response.data);
                        this.$payment.failPayment();
                    });
                this.count = 0;
                return;
            },

            createPaymentWithRegister () {
                if(this.isRegister){
                    return;
                }
                axios.post('/user/fastRegister', {
                    name: this.data.cardholder,
                    email: this.data.email,
                    password: '',
                    phone_code: this.data.phoneCode,
                    phone_number: this.data.phone,
                    payment_form: true,
                    currency: this.$store.currency,
                }).then((response) => {
                    if(response.data.result == 'success'){
                        this.isRegister = true;
                        this.createPayment();
                        return;
                    }
                    this.isProcessing = false;
                    this.$emit('register_error', response.data.data);
                }).catch(error => {
                    this.$emit('register_error', error.response.data.data);
                    this.isProcessing = false;
                });
            },

            loadNethone(){
                let config = this.$config('nethone');
                let gateway = this.$payment.info.data.gateway;
                if(!config[`${gateway.key}_enable`]){
                    return;
                }
                if(document.getElementById('nethone_script')){
                    document.getElementById('nethone_script').remove();
                }   
                let script = document.createElement('script');
                script.setAttribute('id', 'nethone_script');
                script.setAttribute('crossorigin', 'use-credentials');
                script.setAttribute('src', `https://${config.provided_name}.cloudfront.net/s/${config.merchant_id}/${config.script_name}.js`);
                document.body.appendChild(script);
                let scriptID = "nethone_script";
                this.attemptRefrence = _.uuid();
                this.profilingReference = _.uuid();
                var options = {
                    attemptReference: this.attemptRefrence,   // inserted by the backend
                    sensitiveFields: ["card_number"],
                  //  allowedFields: ['cardholder']
                };
                if (window.dftp) {
                    window.dftp.init(options);
                } else {
                    var el = document.getElementById(scriptID);
                    el.addEventListener("load", function () {
                        window.dftp.init(options);
                    });
                }
            }
        }
    }
</script>
<style>
    .loader-wrapper{
        min-height: 300px;
    }
</style>
