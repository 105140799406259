<template>
    <modal-base 
        id="login-modal" 
        :title="$translate('login_to_your_account')"
        dialogClass="modal-dialog-centered"
        :hideFooter="true"
    >
        <div class="container">
            <Form @submit="onSubmit" ref="loginForm">
                <div class="mb-3">
                    <Field 
                        name="email"
                        class="form-control" 
                        :placeholder="$translate('enter_email')"
                        rules="required|email"
                        v-model="email"
                    />
                    <ErrorMessage name="email" as="error" v-slot={message}> </ErrorMessage>  
                </div>   

                <div class="mb-3">
                    <Field 
                        name="password"
                        class="form-control" 
                        :placeholder="$translate('enter_pw')"
                        rules="required"
                        v-model="password"
                        type="password"
                    />
                    <ErrorMessage name="password" as="error" v-slot={message}></ErrorMessage>  
                </div>  

                <div class="mb-3">
                    <a href="#" @click="$router.push('/forgot-password')" data-bs-dismiss="modal"> {{ $translate('forgot_pw') }}?</a>
                </div>

                <div class="mb-3">
                    <button class="btn btn-primary modal-submit">
                        {{ $translate('login') }}
                    </button>
                </div>
            </Form>
        </div>
    </modal-base>    
</template>

<script>
    
    import { Field, Form, ErrorMessage } from 'vee-validate';

    export default {

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        data(){
            return {
                email: '',
                password: '',
            };
        },

        mounted(){
          
        },

        methods: {
            onSubmit(e){
                axios.post('/user/login',{
                    email: this.email,
                    password: this.password
                })
                .then((data) => {
                    this.$auth.check();
                    let redirectUrl = '/dashboard'
                    if (data.data.data.user.role === 'admin') {
                        redirectUrl = '/admin'
                    } 
                    location = redirectUrl;
                }).catch((err) => {
                    this.$refs.loginForm.setErrors({email: this.$translate('user_or_pw_incorrect')});
                });
            },

            resolveErrors(errors, message){
               // console.log(errors, message);
            }       
        }    
    }

</script>