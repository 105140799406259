<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="loader-old">
                        <div class="logo-load">
                            <img src="/img/front/icon-logo.png" data-rjs="2" alt="locateany" style="width: 150px;">
                        </div>
                        <div v-show="address.length === 0 && error.length === 0" id="loader" />
                    </div>
                    <div v-show="address.length > 0" id="myDiv" class="animate-bottom">
                        <h2>Your location</h2>
                        <p id="address">
                            {{ address }}
                        </p>
                    </div>
                    <div v-show="error.length > 0" style="text-align: center; color: #dc3545;">
                        <p>{{ error }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    layout: 'static',
    components: {},
    data () {
        return {
            id: this.$route.params.id,
            message: '',
            currentLatitude: null,
            currentLongitude: null,
            address: '',
            error: ''
        }
    },
    mounted () {
        this.initMap();
    },
  methods: {
    initMap () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback, {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 10000
            })
        } else {
            this.errorCode()
            this.error = 'Sorry, your browser does not support HTML5 geolocation.'
        }
    },
    successCallback (position) {
        this.currentLatitude = position.coords.latitude
        this.currentLongitude = position.coords.longitude
        if (this.currentLatitude !== null && this.currentLongitude !== null) {
            this.successCode()
        } else {
            this.errorCode()
            this.error = 'Sorry, your browser does not support HTML5 geolocation.'
        }
    },
    errorCallback (error) {
        if (error.code === 1) {
            this.error = 'Geolocation is not enabled. Please enable to use this feature.'
            this.message = 'You\'ve decided not to share your position, but it\'s OK. We won\'t ask you again.'
        } else if (error.code === 2) {
            this.message = 'The network is down or the positioning service can\'t be reached.'
            this.error = 'The network is down or the positioning service can\'t be reached.'
        } else if (error.code === 3) {
            this.message = 'The attempt timed out before it could get the location data.'
            this.error = 'The attempt timed out before it could get the location data.'
        } else {
            this.message = 'Geolocation failed due to unknown error.'
            this.error = 'Geolocation failed due to unknown error.'
        }

        this.errorCode()
    },
    successCode () {
        this.$geocoder.send({
            lat: this.currentLatitude,
            lng: this.currentLongitude
        }, (res) => {
            if (res.results.length > 0) {
                this.address = res.results[0].formatted_address;
                axios.post(`/user/locate/confirm/${this.id}`, {
                    lat: this.currentLatitude,
                    lng: this.currentLongitude,
                    address: this.address,
                    status: 'success'
                });
            }
        })
    },
    errorCode () {
        axios.post(`/user/locate/reject/${this.id}`)
    }
  }
}
</script>

<style scoped>
  /* Center the loader */
  #loader {
    position: absolute;
    left: 51%;
    top: 80%;
    z-index: 1;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }

  @-webkit-keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0
    }
    to {
      bottom: 0px;
      opacity: 1
    }
  }

  @keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0
    }
    to {
      bottom: 0;
      opacity: 1
    }
  }

  .loader-old {
    position: relative;
    height: 50vh;
    transform: translate(0%, 50%);
  }

  .logo-load {
    position: absolute;
    height: 180px;
    text-align: center;
    left: 0;
    top: 40%;
    right: 0;
    transform: translate(0%, -60%);
  }

  #myDiv {
    /*display: none;*/
    text-align: center;
  }

  @media (max-width: 991px) {
    #loader {
      width: 100px;
      height: 100px;
      top: 75%;
    }
  }
</style>
