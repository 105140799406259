<template>
    <div class="container terms-and-conditions-content page-content">
        <div v-html="$translate('terms_and_conditions_text')"></div>
    </div>
</template>

<script>
    export default{
        computed:{
            email_link(){
                return `<a href="mailto:${this.$config('company_email')}">${this.$config('company_email')}</a>`;
            }
        }
    }
</script>