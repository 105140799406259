<template>
    <div class="payment-description">
        <div class="row">
            <div class="col-md-4 payment-about-item">
                <div class="icon">
                    <img class="icon-secure-payment" src="/img/front/icons/icon-secure-payment.svg" alt="Secure payment">
                </div>
                <h3>{{ $translate('secure_pay') }}</h3>
                <p>{{ $translate('secure_pay_text') }}</p>
            </div>
            <div class="col-md-4 payment-about-item">
                <div class="icon">
                    <img class="icon-anonymous" src="/img/front/icons/icon-anonymous.svg" alt="Anonymous">
                </div>
                <h3>{{ $translate('anon') }}</h3>
                <p>{{ $translate('anon_text') }}</p>
            </div>
            <div class="col-md-4 payment-about-item">
                <div class="icon">
                    <img class="icon-precise-location" src="/img/front/icons/icon-precise-location.svg" alt="Precise geolocation">
                </div>
                <h3>{{ $translate('prec_geo') }}</h3>
                <p>{{ $translate('prec_geo_text') }}</p>
            </div>
        </div>

        <div class="row">
            <div class="payNote col-12 pl-0 pr-0">
                <p v-html="$translate('payment_note')"></p>
                <p v-html="$translate('payment_note2')"></p>
            </div>
        </div>
    </div>    
</template>