window._ = require('lodash');
window._ = require('lodash-uuid');
window.axios = require('axios');
window.bootstrap = require('bootstrap');

let csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
let axiosOpt = window.axios.defaults.headers;

axiosOpt.common['X-CSRF-Token'] = csrfToken;
axiosOpt.common['X-Requested-With'] = "XMLHttpRequest";
axiosOpt.post['Content-Type'] = "application/json";
axiosOpt.post['Accept'] = "application/json";

import { createApp } from 'vue';

import Auth from './plugins/auth';
import Router from './router/router.js';
import VueTheMask from 'vue-the-mask';

import App from './components/App';
import Config from './plugins/config';

import Store from './plugins/store';

import Modals from './plugins/modal';
import VeeValidate from './plugins/vee-validate';
import Geocoder from './plugins/geocoder';
import i18n from './plugins/i18n';
import Payment from './plugins/payment';
import {loadMessages} from './plugins/i18n';

const app = createApp(App);

app.use(Store);

app.use(Auth);

app.use(Router, app);

app.use(VueTheMask);

app.use(Config);

app.use(Payment);

app.use(i18n);

app.use(Modals);

app.use(VeeValidate);

app.use(Geocoder, {
    defaultLanguage: 'en', // e.g. 'en'
    defaultMode: 'lat-lng', // or 'lat-lng'
    googleMapsApiKey: app.config.globalProperties.$config('google_api_key')
});

(async function () {
   // await loadMessages(app.config.globalProperties.$store.locale);
    
});

app.mount('#app');

try {
    ga('set', 'page', Router.currentRoute.path);
    ga('send', 'pageview');
    
    Router.afterEach(( to, from ) => {
        ga('set', 'page', to.path);
        ga('send', 'pageview');
    });
} catch (err) {}


    
