import { reactive} from "vue";

export default {     
    install(app, options) {
        app.config.globalProperties.$auth = {
            user: reactive({data: null}),
            async check() {
                await axios.get('/user/me')
                    .then(resp => {
                        this.user.data = resp.data.data.user || null;
                    })
                    .catch(err => {
                        
                    });
            },
            logout() { 
                axios.post('/user/logout')
                    .then((resp) => {
                        this.user.data = null;
                        location = '/';
                    });
            },
            isAuth() {
                return this.user.data ? true : false;
            }
        };
        app.config.globalProperties.$auth.check();
    }
}
