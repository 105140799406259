<template>
    <div>
        <div class="container-fluid footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 footer-logo">
                        <div class="logo">
                            <img class="img-logo-invert" :src="$config('footer_logo')">
                        </div>

                        <div class="contacts">
                            <div>
                                <img class="img-mail" src="/img/front/icons/mail.svg" alt="Email">
                                <a :href="'mailto:' + $config('company_email')">{{ $config('company_email') }}</a>
                            </div>
                            <div>
                                <img class="img-phone" src="/img/front/icons/phone.svg" alt="Phone">
                                <div class="phoneNumbers">
                                    <a :href="'tel:'+ $config('company_phone')">{{ $config('company_phone') }}</a>
                                </div>
                            </div>
                            <div>
                              <br>
                              <div class="contacts-company_information">
                                    {{ $translate('company_information') }}:
                              </div>
                              <br>
                              <div class="contacts-company_information">
                                  {{$config('company_name')}}<br>
                                  <span v-html="company_address"></span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 footer-links">
                        <h3>{{ $translate('pages') }}</h3>
                        <div class="footer-link">
                            <a href="/#how-it-works">
                                {{ $translate('how_it_works') }}
                            </a>
                        </div>
                        <div class="footer-link">
                            <router-link :to="{ name: 'pricing' }" class="small ml-auto my-auto">
                              {{ $translate('pricing') }}
                            </router-link>
                        </div>
                        <div class="footer-link">
                            <router-link :to="{ name: 'faq' }" class="small ml-auto my-auto">
                                {{ $translate('faq') }}
                            </router-link>
                        </div>
                        <div class="footer-link">
                            <router-link :to="{ name: 'contact_us' }" class="small ml-auto my-auto">
                                {{ $translate('contact_us') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-6 footer-links">
                        <h3>{{ $translate('account') }}</h3>
                        <div class="footer-link" v-if="!$auth.isAuth()">
                            <a href="javascript:" data-bs-toggle="modal" data-bs-target="#login-modal">{{ $translate('login') }}</a>
                        </div>
                        <div class="footer-link link-register" v-if="!$auth.isAuth()">
                            <a href="javascript:" data-bs-toggle="modal" data-bs-target="#register-modal">{{ $translate('register') }}</a>
                        </div>
                        <div class="footer-link">
                            <a href="javascript:" @click="openUnsubscribe">{{ $translate('unsubscribe') }}</a>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 footer-links">
                        <h3>{{ $translate('policies') }}</h3>
                        <div class="footer-link">
                            <router-link :to="{ name: 'cookie_policy' }" class="small ml-auto my-auto">
                                {{ $translate('cookies_policy') }}
                            </router-link>
                        </div>
                        <div class="footer-link">
                            <router-link :to="{ name: 'privacy' }" class="small ml-auto my-auto">
                              {{ $translate('privacy_policy') }}
                            </router-link>
                        </div>
                        <div class="footer-link">
                            <router-link :to="{ name: 'terms_and_conditions' }" class="small ml-auto my-auto">
                              {{ $translate('terms_and_conditions') }}
                            </router-link>
                        </div>
                    </div>

                    <div class="col-md-12 text-end my-3">
                        <span class="text-white">{{ $translate('language') }} - </span>
                        <select v-model="$store.locale">
                            <option
                                v-for="(value, key) in $config('locales')"
                                :value="key"
                            >
                                {{ value }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-12 text-end">
                        <span class="text-white">{{ $translate('currency') }} - </span>
                        <select v-model="$store.currency">
                            <option
                                v-for="(currency, i) in $config('currencies')"
                                :key="`currency-${i}`"
                                :value="currency.name"
                            >
                                {{ currency.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="container-fluid footer2">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="copyright">
                            &copy; copyright {{ new Date().getFullYear() }} by <a href="/">{{ $config('app_name') }}</a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="payment-icons">
                            <img src="/img/front/icons/payment-icon-1.png" alt="VISA">
                            <img src="/img/front/icons/payment-icon-2.png" alt="MasterCard">
                            <img src="/img/front/icons/payment-icon-3.png" alt="Maestro">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>

export default {
    computed: { 
        company_address(){
            return window.config.company_address.replace(/\n/g, "<br>");
        },
    },
    methods: {
        openUnsubscribe () {
            let text = `${this.$translate('you_can_unsub')}
                    <a href="/dashboard/subscriptionHistory?unsubscribe=true" target="_blank">${this.$translate('here')}</a>
                    ${this.$translate('must_be_auth')} <hr>${this.$translate('alt_send_mail')}`;
            this.$modals.notification(text);
        }
    },
}
</script>