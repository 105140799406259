import { defineRule } from 'vee-validate';
import { required, email, digits, integer, numeric, min, max } from '@vee-validate/rules';
import { Field, Form, ErrorMessage } from 'vee-validate';


defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('digits', digits);
defineRule('integer', integer);
defineRule('numeric', numeric);

export default {
    install: (app, options) => {
        app.component('Form', Form);
        app.component('Field', Field);
        app.component('error-message', ErrorMessage);
    }
}