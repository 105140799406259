<template>
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        >
        </payment-form>

        <teleport to="body">
            <IframeModal 
                url="/payments/iframes/konnemerchantpay"
                :data="iframeData"
            />
        </teleport>  
    </payment>
</template>

<script>

import PaymentForm from '../../forms/PaymentForm';
import IframeModal from '../../modals/IframeModal';
import Payment from '../PaymentContainer';

export default {
    components: {
        PaymentForm, Payment, IframeModal
    },  
    props: {
        useCurrency: {
            type: String,
            default: ''
        },
        currencySymbol: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            isProcessing: false,
            terms: true,
            currencyCode: null,
            rate: null,
            usualRate: null,
            planType: null,
            errors: [],
            iframeData: {},
        }
    },
    mounted(){ },
    methods: {
        registerError(data){
            this.errors = data;    
        },

        submitForm(data){
            data.browserData = this.getBrowserData();
            data.country = this.$store.phone_iso;
            this.$refs.payment.processPayment(data);
        },

        pending(data){
            this.iframeData = data.data;
         //   this.$refs.payment.isProcessing = true;
          /*  let script = document.createElement('script');
            script.innerHTML = data.data.script; 
            document.body.appendChild(script);*/
        },

        getBrowserData () {
            const navigator = window.navigator;  
            return JSON.stringify({
                acceptHeader: 'application/json',
                userAgent: navigator.userAgent,
                language: navigator.language,
                timezone: (new Date()).getTimezoneOffset().toString(),
                colorDepth: screen.colorDepth,
                screen: {
                    height: screen.height.toString(),
                    width: screen.width.toString()
                },
                javaScriptEnabled: true,
                javaEnabled: navigator.javaEnabled()
            });
        },
    }
}
</script>