<template>
    <div class="container proccesing-content">
        <div :class="{'d-none': loaded}">
            <h1>{{ loadingText }}</h1>
            <div class="loading-bar">
                <div class="loading-bar-background" />
                <div class="loading-bar-foreground" />
                <div class="loading-bar-fill" />
            </div>
        </div>

        <div :class="{'d-none': !loaded}">
            <div class="locate-status">
                <img class="img-loaded" src="/img/front/loaded.svg" alt="Ready">
                <div class="text">
                    {{ $translate('number_available') }}
                </div>
                <div>
                    <router-link :to="{ name: 'payment' }" class="small ml-auto my-auto">
                      {{ $translate('proceed_to_payment') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loaded: false,
                loadingText: this.$translate('checking_phone'),
                loadingTextList: [
                    this.$translate('con_to_db'),
                    this.$translate('detecting_oper'),
                    this.$translate('receiving_loc')
                ],
                timers: []
            }
        },
        destroyed () {
            this.timers.forEach((x) => {
                clearInterval(x);
            })
        },
        mounted () {
            this.timers.push(setTimeout(() => {
                this.loadingText = this.loadingTextList[0]
                this.timers.push(setTimeout(() => {
                    this.loadingText = this.loadingTextList[1]
                    this.timers.push(setTimeout(() => {
                      this.loadingText = this.loadingTextList[2]
                    }, 1300))
                }, 1300))
            }, 1300))

            this.timers.push(setTimeout(() => {
                this.loaded = true
                this.timers.push(setTimeout(() => {
                    this.$router.push('/payment')
                }, 2500))
            }, 5500))
        }
    }
</script>
