<template>
    <div class="container privacy-policy-content page-content">
        <div v-html="$translate('privacy_policy_text')"></div>
    </div>
</template>

<script>
    export default{
        computed:{
            email_link(){
                return `<a href="mailto:${this.$config('company_email')}">${this.$config('company_email')}</a>`;
            }
        }
    }
</script>

