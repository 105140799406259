<template>
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
            ref="paymentForm"
        >
            <template v-slot:payment_data>
                <div id="payment-container" class="col-12">
                    <component :is="'script'" type="application/json">
                        {{
                            {
                                "payButtonText": "Pay Now",
                                "fontFamily": "Roboto, sans-serif",
                                "onPaymentResult": "executeMePlease",
                                "externalPaymentButton": "myCheckoutButton",
                                "fontFamily":"Roboto",
                                "labels":{
                                    "cardExpireDateShort":$translate('exp_date'),
                                    "cardSecurityCode":$translate('cvv'),
                                },
                                "customClasses": {
                                    "cardHolderNameContainer": "form-group",
                                    "cardHolderName": "form-control",
                                    "cardNumberContainer": "form-group",
                                    "cardNumber": "form-control",
                                    "cardExpireDateShortContainer": "form-group",
                                    "cardExpireDateShort": "form-control",
                                    "cardSecurityCodeContainer": "form-group",
                                    "cardSecurityCode": "form-control"
                                }
                           }
                        }}
                    </component>
                    <component :is="'script'" type="text/javascript">
                        function executeMePlease(response) {
                            window.dispatchEvent(new CustomEvent('completePayment'));
                        }
                    </component>
                </div>
            </template>
            <template v-slot:submit-button>
                <button id="myCheckoutButton"  @click="paymentButton($event)">Pay Now</button>  
            </template>
        </payment-form>
    </payment>
</template>

<script>

    import PaymentForm from '../../forms/PaymentForm';
    import Payment from '../PaymentContainer';

    export default {
        components: {
            PaymentForm, 
            Payment
        }, 
        watch:{
            '$store.currency' : function(){
               // 
            }
        },
        data() {
            return {
                isProcessing: false,
                currencyCode: null,
                errors: [],
                data: {},
                cardholder: '',
                merchantToken: '',
                
            }
        },
        mounted(){
            let data = {
                phone: this.$store.phone_number,
                phoneCode:  this.$store.phone_code,
                cardholder: '_',
                card_number: '_', 
                expiry: '00/00',
                cvv: '***',
                email: this.$store.email,
            };
           
            
            this.$refs.payment.processPayment(data);   
            let app = this;
            window.addEventListener('completePayment', function (e) {
                app.checkTransaction();
            });

            document.addEventListener('payButtonClick', function (e) {
                e.preventDefault();
                app.cardholder = document.querySelector("#c2pCardHolderName").value;
            });
            
        },

        computed: {
            currentCurrency(){
                return this.$store.currency;
            }
        },
        methods: {
            registerError(data){
                this.errors = data;    
            },

            submitForm(data){
                this.data = data;
            },

            checkTransaction(){
                let data = {
                    merchantToken: this.merchantToken,
                    paymentToken: this.payXpertClientToken,
                    currency: this.currentCurrency,
                    name: this.cardholder,
                };
                axios.post("/payments/complete/payxpert", data)
                .then(resp => {
                    if(resp.data.result == 'success'){
                        this.$payment.successPayment();
                    }else{
                        this.$payment.failPayment();
                    }    
                })
            },

            pending(data){
                this.payXpertClientToken = data.data.customerToken;
                this.merchantToken = data.data.merchantToken;
                this.loadPayxpert();
            },

            loadPayxpert(){
                if(document.getElementById('payxpert_script')){
                    document.getElementById('payxpert_script').remove();
                }
                let script = document.createElement('script')
                script.setAttribute('id', 'payxpert_script')
                script.setAttribute('data-mount-in', '#payment-container')
                script.setAttribute('crossorigin', 'anonymous')
                script.setAttribute('src', 'https://connect2.payxpert.com/payment/' + this.payXpertClientToken + '/connect2pay-seamless-v1.4.4.js')
                document.body.appendChild(script);
                
            },

            paymentButton(e){
                e.preventDefault()
            },
        }
    }
</script>

<style lang="css">
    #c2pCreditCardForm{
        padding: 0;
        width: 100%;
        max-width: 100%;
    }
    #payment-container div[role="dialog"]{
        z-index: 9000;
    }
    #email-input:disabled{
        opacity: 0.5;
    }
    .consentBox{
        padding-left: 0;
    }
    #myCheckoutButton,
    #myCheckoutButtonDisabled{
        background: #00D98B;
        border-radius: 5px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        width: 300px;
        min-height: 48px;
        outline: none;
        cursor: pointer;
        transition: .3s linear;
        border: none transparent;
    }
    #myCheckoutButtonDisabled,
    #myCheckoutButton:disabled{
        background: #eee;
    }
    #myCheckoutButtonDisabled:hover,
    #myCheckoutButton:disabled:hover{
        background: #eee!important;
    }
    #myCheckoutButton:disabled,
    #myCheckoutButtonDisabled:disabled{
        opacity: 0.5;
    }
    #myCheckoutButton:hover,
    #myCheckoutButtonDisabled:hover{
        background: #00CC81;
    }
    #c2pCreditCardForm input,#email-input{
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        box-sizing: border-box;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .125rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    #c2pCreditCardForm {
        background-color: transparent;
        box-shadow: none;
    }
    #payment-container > input[type="text"]{
        font-size: 16px;
        border: none;
        display: block;
        outline: none;
        width: 100%;
        color: #495057;
        font-weight: 400;
        line-height: 24px;
        background-color: #fff;
        font-family: sans-serif;
    }
    .form-group,
    #c2pCardInlineFields{
        margin-bottom: 1rem;
        margin-top: 0!important;
    }
    #c2pSubmitButtonContainer{
       /* display: none;*/
    }
    #c2pCardExpireDateShortContainer,
    #c2pCardSecurityCodeContainer{
        margin-bottom: 0;
    }
    #payment-container label{
        font-family: Roboto, sans-serif;
        letter-spacing: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #001F72;
    }

    #payment-container input{
        margin-top: 0 !important;
        letter-spacing: normal !important;
        font-family: Roboto, sans-serif !important;
    }
</style>

<style lang="scss" scoped>

    .loader-wrapper {
        width: 220px;
        height: 220px;
        overflow: hidden;
        margin: 0 auto;
    }

    .paymentForm {
        background: #F1F9FF;
        border-radius: 10px;
        width: 100%;

        #payment-container {
            width: 100%;
            overflow: hidden;
            z-index: 1040;
            position: relative;
            min-height: 250px;
        }
    }

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
        margin: 60px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    #c2pCreditCardForm{
        max-width: 100%;
    }
    #c2pCardHolderName{
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .125rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
</style>