<template>
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        >
        </payment-form>
        <teleport to="body">
            <IframeModal 
                url="/payments/iframes/emerchantpay"
                :data="iframeData"
            />
        </teleport>  
    </payment>
</template>

<script>

    import PaymentForm from '../../forms/PaymentForm';
    import IframeModal from '../../modals/IframeModal';
    import Payment from '../PaymentContainer';

    export default {
        components: {
            PaymentForm, Payment, IframeModal
        }, 
        data() {
            return {
                errors: [],
                iframeData: {},
            }
        },
        mounted(){
            this.loadScript();
        },
        methods: {
            registerError(data){
                this.errors = data;    
            },

            submitForm(data){
                let cse = Encrypto.createEncryption(this.$config('emerchantpay_public_key'));
                let params = {
                    card_number: data.card_number,
                    expiration_month: data.expiry.split('/')[0],
                    expiration_year: '20' + data.expiry.split('/')[1],
                    cvv: data.cvv
                };
  
                let encryptedData = cse.encrypt(params);
                
             /*   data.card_number = encryptedData.card_number;
                data.expiration_month = encryptedData.expiration_month;
                data.expiration_year = encryptedData.expiration_year;
                data.cvv = encryptedData.cvv;*/
                
                data.card_number = params.card_number;
                data.expiration_month = params.expiration_month;
                data.expiration_year = params.expiration_year;
                data.cvv = encryptedData.cvv;
                
                data.expiry = '_';               
                data.browserData = this.getBrowserData();
                data.country = this.$store.phone_iso;
                this.$refs.payment.processPayment(data);
            },

            pending(data){
                this.iframeData = data.data;
            },

            getBrowserData () {
                const navigator = window.navigator;
                return {
                    acceptHeader: 'application/json',
                    userAgent: navigator.userAgent,
                    language: navigator.language,
                    timezone: (new Date()).getTimezoneOffset().toString(),
                    colorDepth: screen.colorDepth,
                    screen: {
                        height: screen.height.toString(),
                        width: screen.width.toString()
                    },
                    javaScriptEnabled: true,
                    javaEnabled: navigator.javaEnabled()
                };
            },
           
            loadScript(){
                if(document.getElementById('emerchantpay-script')){
                    document.getElementById('emerchantpay-script').remove();
                }
                let script = document.createElement('script');
                script.setAttribute('id', '#emerchantpay-script');
                script.setAttribute('crossorigin', 'anonymous');
                script.setAttribute('integrity', 'sha512-fezEmqlQWwvKq3A2s897RSda3JsDen/xmPTsBmnx6TWk++rnofg2omiNLHhCbQvQ8DtEvfAvXQTsvE95DlELAw==' );
                script.setAttribute('src', 'https://d3ptmkrtf16kmh.cloudfront.net/encrypto-1.0.1.js')
                document.body.appendChild(script);
            }
        }
    }
</script>
