<template>
    <div>
        <slot/>
        <footer-component/>
    </div>
</template>

<script>
import FooterComponent from './../components/Footer'

export default {
    components: {
        FooterComponent
    }
}
</script>
