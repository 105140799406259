const VueRouter = require('vue-router');

import auth from './middleware/auth';
import subscription from './middleware/subscription';
import unsubscription from './middleware/unsubscription';
import unauth from './middleware/unauth';

import MainPage from '../pages/MainPage';
import ContactPage from '../pages/ContactPage';
import CookiePolicy from '../pages/CookiePolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Faq from '../pages/Faq';
import Pricing from '../pages/Pricing';
import Payment from '../pages/Payment';
import NotFound from '../pages/errors/404';
import NotAuth from '../pages/errors/401';
import ForgotPassword from '../pages/ForgotPassword';
import SuccessfullPayment from '../pages/SuccessfullPayment';
import Locate from '../pages/dashboard/Locate';
import SubscriptionHistory from '../pages/dashboard/SubscriptionHistory';
import LocateList from '../pages/dashboard/LocateList';
import DashboardPayment from '../pages/dashboard/Payment';
import Processing from '../pages/Processing';
import GoPage from '../pages/Go';

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainPage,
        meta: {
            layout: 'main'
        }
    },
    
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            
        }
    },
    
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing,
        meta: {
          
        }
    },
    
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
          
        }
    },
    
    {
        path: '/contact-us',
        name: 'contact_us',
        component: ContactPage,
        meta: {
           
        }
    },
    
    {
        path: '/cookie-policy',
        name: 'cookie_policy',
        component: CookiePolicy,
        meta: {
          
        }
    },
    
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyPolicy,
        meta: {
          
        }
    },
    
    {
        path: '/terms-and-conditions',
        name: 'terms_and_conditions',
        component: TermsAndConditions,
        meta: {
          
        }
    },
    
    {
        path: '/processing',
        name: 'processing',
        component: Processing,
        meta: {
            middleware: [unauth]
        }
    },
    
    {
        path: '/payment',
        name: 'payment',
        component: Payment,
        meta: {
            middleware: [unauth]
        }
    },
    
    {
        path: '/successfulPayment',
        name: 'successfull_payment',
        component: SuccessfullPayment,
        meta: {
          
        }
    },
    
    {
        path: '/go/:id',
        name: 'geolocate',
        component: GoPage,
        meta: {
           layout: 'static'
        }
    }, 
    
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Locate,
        meta: {
            middleware: [subscription],
            layout: 'dashboard'
        }
    },
    
    {
        path: '/dashboard/locate',
        name: 'locate',
        component: Locate,
        meta: {
            middleware: [subscription],
            layout: 'dashboard'
        }
    },
    
    {
        path: '/dashboard/locateList',
        name: 'locateList',
        component: LocateList,
        meta: {
            middleware: [subscription],
            layout: 'dashboard'
        }
    },
    
    {
        path: '/dashboard/subscriptionHistory',
        name: 'subscriptionHistory',
        component: SubscriptionHistory,
        meta: {
            middleware: [auth],
            layout: 'dashboard'
        }
    },
    
    
    {
        path: '/dashboard/payment',
        name: 'dashboardPayment',
        component: DashboardPayment,
        meta: {
            middleware: [unsubscription],
            layout: 'dashboard'
        }
    },
    
    
    {
        path: '/unauthenticate',
        name: 'unauthenticate',
        component: NotAuth,
        meta: {
          
        }
    },
    
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        meta: {
            title: 'Not found page'
        }
    }
    
    
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    linkActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
});

const middlewarePipeline = (context, middleware, index) => {
    const nextMiddleware = middleware[index];
    if(!nextMiddleware){
        return context.next;
    }
    return () => {
        const nextPipeline = middlewarePipeline( context, middleware, index + 1)
        nextMiddleware({ ...context, next: nextPipeline });
    };
};

export default {
    install(app, options) {

        router.install(app);
        router.beforeEach((to, from, next) => {
            if (!to.meta.middleware) {
                return next();
            }
            const middleware = to.meta.middleware;
            const context = {
                to,
                from,
                next,
                app
            };
            return middleware[0]({
                ...context,
                next: middlewarePipeline(context, middleware, 1)
            });
            return next();    
        });
        
       
    }
};
