<template>
    <modal-base 
        id="logout-modal" 
        dialogClass="modal-dialog-centered modal-sm"
        :hideHeader="true"
    >
        <div class="loginmodal-container">
            <div class="success-payment">
                <img src="/img/dashboard/dashboard-icon/open-lock.svg" class="img-fluid">
                <p>Are You Sure</p>
                <span>You want to logout</span>
            </div>
        </div>

        <template v-slot:footer>
            <button 
                id="cancel-forget" 
                type="button" 
                class="btn btn-secondary"
                data-bs-dismiss="modal">
                    No
            </button>
            <a href="#" @click="$auth.logout()" class="btn btn-primary">Yes</a>
        </template>
    </modal-base>
</template>
