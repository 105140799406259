<template>
    <Form @submit="onSubmit()" ref="paymentForm">
        <div class="paymentForm">
            <div class="row">
                <slot name="payment_data">
                    <div class="col-12 col-lg-8 col-md-12 mb-md-3">
                        <label>{{ $translate('card_num') }}:</label>
                        <Field 
                            id="card_number"
                            name="card_number" 
                            rules="required|min:16"
                            class="form-control"
                            v-model="card_number"
                            :placeholder="$translate('card_num')"
                            v-mask="'#### #### #### ####'"
                            data-threeds="pan"
                        />
                        <ErrorMessage name="card_number" as="error" v-slot={message}> 
                            Card number is not valid   
                        </ErrorMessage>  
                    </div>
                    <div class="col-12 col-lg-4 col-md-12 mb-md-3">
                        <label>{{ $translate('exp_date') }}:</label>
                        <Field 
                            id="expiry"
                            class="form-control"
                            :placeholder="$translate('mm_yy')"
                            v-mask="'##/##'"
                            data-threeds="mm_yy"
                            name="exp_date"
                            rules="required"
                            v-model="expiry"
                        />
                        <ErrorMessage name="exp_date" as="error" v-slot={message}> 
                            Exp date is not valid 
                        </ErrorMessage>  
                    </div>
                    <div class="col-12 col-lg-8 col-md-12 mb-md-3">
                        <label>{{ $translate('card_holder') }}:</label>
                        <Field
                            id="cardholder"
                            class="form-control"
                            name="card_holder" 
                            rules="required"
                            v-model="cardholder"
                            :placeholder="$translate('card_holder')"
                        />
                        <ErrorMessage name="card_holder" as="error" v-slot={message}> 
                            Card holder is not valid 
                        </ErrorMessage>  
                    </div>   
                    <div class="col-12 col-lg-4 col-md-12 mb-md-3">
                        <label>{{ $translate('cvv') }}:</label>
                        <Field
                            id="cvv"
                            class="form-control"
                            name="cvv" 
                            rules="required|numeric|min:3|max:4"
                            v-model="cvv"
                            :placeholder="$translate('cvv')"
                            maxlength="4"
                            type="password"    
                        />
                        <ErrorMessage name="cvv" as="error" v-slot={message}> 
                            Cvv is not valid 
                        </ErrorMessage>
                    </div>
                </slot>   
                    <div class="col-lg-8 col-md-12 mb-md-3" v-if="!$auth.isAuth()">
                        <label>{{ $translate('email') }}:</label>
                        <Field
                            :disabled="true"
                            v-model="email"
                            :placeholder="$translate('email')"
                            type="email"
                            id="email-input"
                            class="form-control"
                            rules="required|email"
                            name="email"
                        />
                        <ErrorMessage name="email" as="error" v-slot={message}> 
                            Email is not valid 
                        </ErrorMessage>
                    </div>
                    <div class="col-lg-6 col-md-12 mb-md-3" v-if="!$auth.isAuth()">
                        <PaymentPhoneCode :phone-code.sync="phoneCode" label="Code:"/>
                    </div> 
                    <div class="col-lg-6 col-md-12 mb-md-3" v-if="!$auth.isAuth()">
                        <label>{{ $translate('phone') }}:</label>
                        <Field
                            class="form-control"
                            name="phone"
                            rules="required|integer"
                            v-model="phone_number"
                            :placeholder="$translate('phone_eg')"
                            :title="$translate('phone_eg')"
                            type="text"
                            id="phone-input"
                        />
                        <ErrorMessage name="phone" as="error" v-slot={message}> 
                            Phone is not valid 
                        </ErrorMessage>
                    </div>
            </div> 

            <div class="row">
                <div class="col-lg-4 col-12 col-md-6 mt-3">
                    <slot name='submit-button'>
                        <button
                            class="btn btn-pay"
                            style="width: 100%;"
                            id="btn-pay"
                        >
                            {{ $translate('pay_now') }}
                        </button>
                    </slot>    
                </div>
                <div class="col-12 mt-3 consentBox">
                    <label class="consent">
                        <input 
                            name="terms_and_conditions" 
                            type="checkbox"
                            v-model="terms" 
                           
                        />
                        {{ $translate('i_read') }}
                        <a href="/terms-and-conditions" target="_blank">
                            {{ $translate('terms_and_conditions') }}
                        </a>
                    </label>
                    <div>    
                        <ErrorMessage name="terms_and_conditions" as="error" v-slot={message}> 
                            The Terms and Conditions field is required
                        </ErrorMessage>    
                    </div>
                </div>
                <div class="col-12 mt-3 consentBox">
                    <label class="consent">
                        <input 
                            name="chkbox_recurring_payment" 
                            type="checkbox"
                            v-model="chkbox_recurring_payment" 
                            
                        />
                        {{ $translate('chkbox_recurring_payment') }}
                    </label>
                </div>                
            </div>    
        </div>    
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import PaymentPhoneCode from '../forms/PhoneCode';

    export default {
        components: {
            PaymentPhoneCode,
            Field, 
            Form,
            ErrorMessage
        },

        props: {
            internalErrors: {
                type: [Object],
                default: () => {},
            }
        },
        data () {
            return {
                card_number: '',
                expiry: '',
                cardholder: '',
                cvv: '',
                email: '',
                phone_number: '',
                phoneCode: '',
                terms: '',
                chkbox_recurring_payment: ''
            }
        },
        mounted() {
            this.email = this.$store.email;
            this.phone_number = this.$store.phone_number;   
        },
        watch: {
            internalErrors(){
                this.$refs.paymentForm.setErrors(this.internalErrors);
            },
        },
        methods: {
            errorFieldResolve (string, variable, translate) {
                return typeof string !== 'undefined' ? string.replace(variable, translate) : ''
            },
            onSubmit(e){
                this.$emit('submitForm', {
                    email: this.email,
                    phone: this.phone_number,
                    expiry: this.expiry,
                    cardholder: this.cardholder,
                    cvv: this.cvv,
                    phoneCode: this.$store.phone_code,
                    card_number: this.card_number
                });
            },
        }
    }
</script>

