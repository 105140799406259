<template>
    <div class="phone-code-container" :class="class">
        <label v-if="label">{{ label }}</label>
        <v-select
            v-model="activeRegCountry"
            append-to-body
            :clearable="false"
            label="phoneCode"
            :options="countries"
            :reduce="country => country.code"
            :tabindex="-1"
            :filter-by="search"
            :clear-search-on-select="true"
            name="phone_code"
        >
            <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                >
            </template>
            <template #selected-option="{code, phoneCode, countryName}">
                <div>
                    <img
                        class="country-flag"
                        :src="`/img/front/flags/${code.toLowerCase()}.png`"
                        style="width: 24px;"
                        :alt="`${code.toLowerCase()} flag`"
                    >
                    <div class="selectedCountry">
                        <span class="phone-code">+{{ phoneCode }}</span>
                        <span class="country-name" v-show="showCountryName">{{ countryName }}</span>
                    </div>
                </div>
            </template>
            <template v-slot:option="option">
                <img
                    :src="`/img/front/flags/${option.code.toLowerCase()}.png`"
                    style="width: 24px;"
                    :alt="`${option.code.toLowerCase()} flag`"
                >
                <div style="display: inline-block; width: 48px; margin-left: 8px;">
                    +{{ option.phoneCode }}
                </div>
                <div style="display: inline-block; margin-left: 12px;">
                    {{ option.countryName }}
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import countries from './countries';

export default {
    name: 'PaymentPhoneCode',
    props: {
        label: {
            type: String,
            default: ''
        },
        showCountryName: {
            type: Boolean,
            default: true
        },
        isUpdateStore: {
            type: Boolean,
            default: true,
        },
        code:{
            type: String,
            default: ''
        },
        class: {
            type: String,
            default: ''
        }
    },
    components: {
        vSelect
    },
    data () {
        return {
            placement: 'bottom',
            activeRegCountry: null,
            countries: countries
        }
    },
    mounted () {
        if(!this.code){
            this.activeRegCountry = this.$store.phone_iso || 'GB';
        }
    },
    watch: {
        activeRegCountry(value){
            let country = countries.find(x => x.code === value);
            this.$emit('update:phone-code', country.phoneCode);
            if(this.isUpdateStore){
                this.$store.phone_iso = country.code;
                this.$store.phone_code = country.phoneCode;
            }
        }
    },
    methods: {
        search (option, label, search) {
            return (
                option.countryName.toLowerCase().includes(search.toLowerCase()) ||
                option.phoneCode.toLowerCase().includes(search.toLowerCase()) ||
                option.code.toLowerCase().includes(search.toLowerCase())
            )
        }
    }
}
</script>


