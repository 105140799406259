<template>
    <div>
        <div class="jumboheader">
            <div class="jumbocontent container">    
                <topbar-component/>
                <div class="row locate align-items-center">
                    <div class="col">
                        <locate-component/>
                    </div>
                    <div class="col-auto d-none d-md-block">
                        <img src="/img/front/GeoSat-background2.png" alt="overlayImage">
                    </div>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopbarComponent from './TopBar';
import LocateComponent from './Locate';

export default {

    components: {
       TopbarComponent, LocateComponent
    }
}
</script>

