<template>
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        >
        </payment-form>
    </payment>
</template>

<script>

import PaymentForm from '../../forms/PaymentForm';
import Payment from '../PaymentContainer';

export default {
    components: {
        PaymentForm, Payment
    }, 
    data() {
        return {
            isProcessing: false,
            errors: [],
            iframeData: {},
        }
    },
    mounted(){},
    methods: {
        registerError(data){
            this.errors = data;    
        },

        submitForm(data){
            this.$refs.payment.processPayment(data);
        },

        pending(data){
            this.iframeData = data.data;
        },
    }
}
</script>