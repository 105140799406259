<template>
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        >
            <template v-slot:payment_data>
                <div class="col-12 col-lg-8 col-md-12 mb-md-3">
                    <label>{{ $translate('card_num') }}:</label>
                    <span id="card-container" class="input-container form-control"/>
                </div>
                <div class="col-12 col-lg-4 col-md-12 mb-md-3">
                    <label>{{ $translate('exp_date') }}:</label>
                    <span id="expiry-container" class="input-container"/>
                </div>

                <div class="col-12 col-lg-8 col-md-12 mb-md-3">
                        <label>{{ $translate('card_holder') }}:</label>
                        <Field
                            class="form-control"
                            name="card_holder" 
                            rules="required"
                            v-model="cardholder"
                            :placeholder="$translate('card_holder')"
                        />
                        <ErrorMessage name="card_holder" as="error" v-slot={message}> 
                            Card holder is not valid 
                        </ErrorMessage>  
                </div>   

                <div class="col-12 col-lg-4 col-md-12 mb-md-3">
                    <label>{{ $translate('cvv') }}:</label>
                    <span id="cvv-container" class="input-container" />
                </div>
            </template>
        </payment-form>

        <teleport to="body">
            <IframeModal 
                url="/payments/iframes/dalenys"
                :data="iframeData"
            />
        </teleport> 
    </payment>
</template>

<script>

    import PaymentForm from '../../forms/PaymentForm';
    import IframeModal from '../../modals/IframeModal';
    import Payment from '../PaymentContainer';
    import { Field, Form, ErrorMessage } from 'vee-validate';

    const DALENYS_API_ID = window.config.dalenys.DALENYS_API_ID;
    const DALENYS_API_KEY = window.config.dalenys.DALENYS_API_KEY;
    const DALENYS_API_URL = window.config.dalenys.DALENYS_API_URL;

    let style;
    let hfields;
    let dalenysLoaded = false;

    export default {
        components: {
            PaymentForm, 
            Payment, 
            IframeModal,
            Field, 
            Form,
            ErrorMessage
        }, 
        data() {
            return {
                isProcessing: false,
                errors: [],
                iframeData: {},
                card_number: '',
                cardholder: '',
                email: '',
                phone_number: '',
                validationErrors: {},
                data: {},
            }
        },
        mounted(){
            this.loadDalenys();
        },
        methods: {
            errorFieldResolve (string, variable, translate) {
                return typeof string !== 'undefined' ? string.replace( variable, translate ) : ''
            },

            registerError(data){
                this.errors = data;    
            },

            submitForm(data){
                this.data = data;
                this.tokenizeHandler();
            },

            pending(data){
                this.iframeData = data.data;
            },

            dalenysLoad () {
                // Define some style properties to apply on hosted-fields inputs
                style = {
                    input: {
                        'font-size': '16px',
                        color: '#495057',
                        'font-weight': '400',
                        'line-height': '24px',
                        'background-color': '#fff',
                        'font-family': 'sans-serif'
                    },
                    '::placeholder': {
                        'font-size': '16px',
                        color: '#6c757d',
                        'font-weight': '400',
                        'line-height': '24px',
                        'background-color': '#fff',
                        'font-family': 'sans-serif'
                    }
                }
                // Initialize the hosted-fields library

                hfields = window.dalenys.hostedFields({
                    // SDK api key

                    key: {
                        id: DALENYS_API_ID,
                        value: DALENYS_API_KEY
                    },
                    // Manages each hosted-field container
                    fields: {
                        card: {
                            id: 'card-container',
                            placeholder: this.$translate('card_num'),
                            enableAutospacing: true,
                            style
                        },
                        expiry: {
                            id: 'expiry-container',
                            placeholder: 'MM/YY',
                            style
                        },
                        cryptogram: {
                            id: 'cvv-container',
                            placeholder: 'CVV',
                            style
                        }
                    }
                });
                hfields.load();
            },
            loadDalenys () {
                if (!dalenysLoaded) {
                    dalenysLoaded = true
                    const script = document.createElement('script')
                    script.onload = this.dalenysLoad
                    script.type = 'text/javascript'
                    script.src = this.$config('dalenys').DALENYS_API_URL;
                    document.body.appendChild(script)
                }
            },
            tokenizeHandler () {
                hfields.createToken((result) => {
                    if (result.execCode === '0000' || result.execCode === '0001') {
                        let expiry = result.cardValidityDate.replace('-', '/');
                        this.$refs.payment.processPayment({
                            card_number: result.cardCode,
                            expiry: expiry,
                            cvv: "000", 
                            cardholder: this.cardholder,
                            paymentToken: result.hfToken,
                            email: this.data.email,
                            phone: this.data.phone,
                            phoneCode: this.data.phoneCode
                        });
                    } else {
                        this.$payment.failPayment();
                    }
                })
            },
        }
    }
</script>

<style>
    .hosted-fields-valid-state {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
    }

    .input-container, input[type=text] {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .hosted-fields-invalid-state {
        border: 2px solid red;
    }
</style>
