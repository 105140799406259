<template>
    <div id="app-dashboard" style="height: 100%;">
        <sidebar-dashboard/>
        <slot/>     
        <Footer/>
    </div>
</template>

<script>

    import SidebarDashboard from '../components/dashboard/Sidebar';
 // import PreloaderDashboard from '~/front/components/dashboard/Preloader';
    import Footer from "../components/Footer";

    export default {
        name: 'DashboardLayout',
        components: {SidebarDashboard,  Footer},
        created() {
            if(this.$route.name == 'locateList'){
                //location = '/dashboard';
            }

           // console.log(this.$route.name);
        },

    }
</script>



