<template>
    <div>
        <header-component/>
        <div class="container services-content">
            <div class="row">
                <div class="col-md-4 service-item">
                    <div class="icon">
                      <img class="icon-services-countries" src="/img/front/icons/icon-services-countries.svg" alt="All countries">
                    </div>
                    <h3>{{ $translate('services_head_all_countries') }}</h3>
                    <p>{{ $translate('services_text_all_countries') }}</p>
                </div>
                <div class="col-md-4 service-item">
                    <div class="icon">
                        <img class="icon-services-networks" src="/img/front/icons/icon-services-networks.svg" alt="All networks">
                    </div>
                    <h3>{{ $translate('services_head_all_networks') }}</h3>
                    <p>{{ $translate('services_text_all_networks') }}</p>
                </div>
                <div class="col-md-4 service-item">
                    <div class="icon">
                        <img class="icon-services-website" src="/img/front/icons/icon-services-website.svg" alt="One website">
                    </div>
                    <h3>{{ $translate('services_head_all_one_web') }}</h3>
                    <p>{{ $translate('services_text_all_one_web') }}</p>
                </div>
            </div>    
        </div>
        <div class="feature-content" id="how-it-works">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <h2>
                            <img class="icon-feature" src="/img/front/icons/icon-feature.svg" alt="Feature">
                            {{ $translate('feature_head_finding') }}
                        </h2>

                        <div class="list-items">
                            <div class="list-item">
                                <img class="icon-check" src="/img/front/icons/icon-check.svg" alt="list-item">
                                <span v-html="$translate('feature_ul_finding_1')"></span>
                            </div>
                            <div class="list-item">
                                <img class="icon-check" src="/img/front/icons/icon-check.svg" alt="list-item">
                                <span v-html="$translate('feature_ul_finding_2')"></span>
                            </div>
                            <div class="list-item">
                                <img class="icon-check" src="/img/front/icons/icon-check.svg" alt="list-item">
                                {{ $translate('feature_ul_finding_3') }}
                            </div>
                            <div class="list-item">
                                <img class="icon-check" src="/img/front/icons/icon-check.svg" alt="list-item">
                                {{ $translate('feature_ul_finding_4') }}
                            </div>
                            <div class="list-item">
                                <img class="icon-check" src="/img/front/icons/icon-check.svg" alt="list-item">
                                {{ $translate('feature_ul_finding_5') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <h2 class="h2-last">
                            {{ $translate('feature_head_how') }}
                        </h2>

                        <div class="how-it-works">
                            <div class="item">
                                <div class="img">
                                    <img class="icon-howitworks" src="/img/front/icons/icon-howitworks.svg" alt="Enter a number">
                                </div>
                                <div class="description">
                                    <h4>{{ $translate('feature_subhead_how_1') }}</h4>
                                    <p>{{ $translate('feature_text_how_1') }}</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img class="icon-howitworks-1" src="/img/front/icons/icon-howitworks-1.svg" alt="Geo-Location">
                                </div>
                                <div class="description">
                                    <h4>{{ $translate('feature_subhead_how_2') }}</h4>
                                    <p>{{ $translate('feature_text_how_2') }}</p>
                                </div>
                            </div>
                            <div class="item">
                                <div class="img">
                                    <img class="icon-howitworks-2" src="/img/front/icons/icon-howitworks-2.svg" alt="Viewing the location">
                                </div>
                                <div class="description">
                                    <h4>{{ $translate('feature_subhead_how_3') }}</h4>
                                    <p>{{ $translate('feature_text_how_3') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        <div class="container testimonials-content">
            <div class="row">
                <div class="col-md-4 testimonial-item">
                    <div class="icon">
                        <img src="/img/front/icons/quote.png" alt="Quote" class="quote">
                    </div>
                    <p>{{ $translate('testimotial_1') }}</p>
                    <div class="author">
                        Shan W
                    </div>
                    <div class="rating">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                    </div>
                </div>
                <div class="col-md-4 testimonial-item">
                    <div class="icon">
                        <img src="/img/front/icons/quote.png" alt="Quote" class="quote">
                    </div>
                    <p>{{ $translate('testimotial_2') }}</p>
                    <div class="author">
                        Faysal M
                    </div>
                    <div class="rating">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                    </div>
                </div>
                <div class="col-md-4 testimonial-item">
                    <div class="icon">
                        <img src="/img/front/icons/quote.png" alt="Quote" class="quote">
                    </div>
                    <p>
                        {{ $translate('testimotial_3') }}
                    </p>
                    <div class="author">
                        Nadia S
                    </div>
                    <div class="rating">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                        <img src="/img/front/icons/stars.svg" alt="Star">
                    </div>
                </div>
            </div>
        </div>

        <div class="container benefit-content">
            <div v-html="$translate('landing_benefits_block')"></div>
        </div>
    </div>
</template>



<script>
    import HeaderComponent from '../components/landing/Header';

    export default {
        components: {HeaderComponent},
        mounted(){
            if(this.$route.query.message){
                this.$modals.notification(this.$translate(this.$route.query.message));
            }
        },
        methods: {
            
        }
    }
</script>