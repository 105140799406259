<template>
    <div class="container error-content">
        <div class="row error-page">
            <div class="col-xl-7 col-lg-8 col-md-18 error-page-left">
                <h1 class="section-title"> Oops!Page Not Found. </h1>
                <p>Sorry but we are unable to find the page that you are looking for.</p>
                <a href="/" class="btn btn-info btn-back-to-home">
                    BACK TO HOME
                </a>
            </div>
            <div class="col-xl-6 col-lg-7 col-md-18 error-page-right">
                <img src="/img/404.svg" alt="error image" class="error-image">
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        mounted () {
            setTimeout(() => {
                window.location = '/';
            }, 3000)
        },
    }
</script>


