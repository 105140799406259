<template>
    <div class="">
        <h1 v-html="$translate('header_locate_phone')" />
        <p class="text" v-html="$translate('header_let_find')" />

        <div>
            <Form class="main-header-form" @submit="onSubmit" ref="locateForm">
                <div class="mb-3">
                    <div class="phone-locate-container">
                        <phone-code :showCountryName="false"/>
                        <Field 
                            name="phone"
                            class="form-control" 
                            :placeholder="$translate('enter_phone_number')"
                            rules="required|numeric|min:3"
                            v-model="$store.phone_number"
                        />          
                    </div>  
                    <ErrorMessage name="phone" as="error"> </ErrorMessage> 
                </div>  
               
                <div class="mb-3" v-if="$store.phone_number">
                    <Field 
                        name="email"
                        class="form-control" 
                        :placeholder="$translate('email')"
                        rules="required|email"
                        v-model="$store.email"
                    />   
                    <ErrorMessage name="email" as="error"> </ErrorMessage> 
                </div>    
                <div>
                    <button class="btn btn-success btn-locate">
                        {{ $translate('btn_locate_number') }}
                    </button>
                </div>
            </Form>
        </div>
        
    </div>
</template>

<script>
    import PhoneCode from '../forms/PhoneCode';
    import { Field, Form, ErrorMessage } from 'vee-validate';
    export default {
        components: {
            PhoneCode, Field, Form, ErrorMessage
        },
        data() {
            return {
                showEmail: false,
                errors: {
                    phoneNumber: false,
                    uniqueEmail: false,
                    emailErrors: [],
                }
            }
        },

        methods: {

            
            onSubmit(){
                let email = this.$store.email;
                axios.post("/user/uniqueEmail",{
                    email: email
                }).then(resp => {
                    if(resp.data.result == 'fail'){
                        this.$refs.locateForm.setErrors(resp.data.data.errors);
                        return;
                    }
                    axios.get(`/user/checkNumber?number=${this.$store.phone_number}&countryCode=${this.$store.phone_iso}`)
                        .then((resp) => {
                            if(resp.data.status == 'error'){
                                this.$refs.locateForm.setErrors({phone: resp.data.message});
                                return;
                            }
                            this.$router.push('processing');
                        });
                });
            }
        }
    }
</script>

