<template>
    <div class="container successfull-content">
        <div class="locate-status">
            <img class="img-loaded" src="/img/front/loaded.svg" alt="Ready">
            <div class="text">
                {{ $translate('pay_success') }}
            </div>
            <div class="description">
                <p>{{ $translate('pay_received') }}</p>
                <a :href="dashboardUrl">{{ $translate('go_to_dash') }}</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        dashboardUrl () {
          return '/dashboard'
        }
    },
    mounted () {
        setTimeout(() => {
            window.location.href = this.dashboardUrl
        }, 2500)
    },
}
</script>