<template>
    <div class="container pricing-content">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-4">
                <div class="section-title">
                    <h2>{{ $translate('pricing') }}</h2>
                        <p v-html="$translate('pricing_offer')">
                    </p>
                </div>
            </div>
            <div class="col-lg-8 pricing-block">
                <div class="price-card">
                    <div class="body">
                        <h3>{{ $translate('trial_days') }}</h3>
                        <p class="mt-1">
                            {{ $translate('locate_unlimited') }}
                        </p>
                        <div class="price my-2">
                            {{ $translate('price_tag')}}
                          <small>{{ $translate('price_time') }}</small>
                        </div>
                        <div class="mb-4 mt-3">
                            <a href="javascript:" class="btn btn-primary" @click="$router.push('/')">{{ $translate('btn_start_geo') }}</a>
                        </div>
                        <small class="mt-2">
                            {{ $translate('price_description') }}
                        </small>
                    </div>
                </div>
                <div class="card pricing-features">
                    <div class="body">
                        <ul class="list icons">
                            <li class="text-white">
                                {{ $translate('world_cover') }}
                            </li>
                            <li class="text-white">
                                {{ $translate('precise_loc') }}
                            </li>
                            <li class="text-white">
                                {{ $translate('all_phones') }}
                            </li>
                            <li class="text-white">
                                {{ $translate('unlimited_geo') }}
                            </li>
                            <li class="text-white">
                                {{ $translate('works_any_net') }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>
