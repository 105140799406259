import ModalBase from '../components/modals/BaseModal';

export default {
        
        
    install: (app, options) => {
        app.config.globalProperties.$modals = {
            hide(id){
                let modal = bootstrap.Modal.getInstance(document.getElementById(id));
                if(!modal){
                    modal = new bootstrap.Modal(document.getElementById(id), {});
                }
                modal.hide();
            },
            show(id){
                let modal = bootstrap.Modal.getInstance(document.getElementById(id));
                if(!modal){
                    modal = new bootstrap.Modal(document.getElementById(id), {});
                }
                modal.show();
            },
            
            notification(text){
                let id = 'notification-modal';
                let modal = bootstrap.Modal.getInstance(document.getElementById(id), {});
                if(!modal){
                    modal = new bootstrap.Modal(document.getElementById(id), {});
                }
                document.getElementById(id).querySelector('.modal-body').innerHTML = text;
                modal.show();
            }
        };
        
        app.component('modal-base', ModalBase);
    }
}
