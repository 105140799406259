<template>
    <div class="container payment-content payment-dashboard-content">
        <div class="row justify-content-center">
            <div class="col-md-9 col-12">
                <div class="rate">
                    <h3 v-if="$config('debug') && $payment.info.data">
                        {{$payment.info.data.gateway.name}}
                    </h3>
                    <span style="display: inline-block; padding-bottom: 12px" class="me-2">
                        {{ $translate('rate') }}: 
                    </span>
                    <select
                        v-model="$store.currency"
                        class="langSelect me-2"
                    >
                        <option
                            v-for="(currency, i) in $config('currencies')"
                            :value="currency.name"
                        >
                            {{ currency.name }}
                        </option>
                    </select>
                    <span class="amount">
                        {{$payment.getAmount().toFixed(2)}}
                    </span>
                </div>

                <div class="payment-text">
                    {{ $translate('payment_pre') }} <span class="green">{{ $translate('payment_pre_safe') }}</span>
                </div>

                <div style="text-align: center; padding: 16px;">
                    <img src="/img/front/payment-logos.png" style="max-width: 90%;" alt="payment logos">
                </div>

                <component :is="gateway"/> 
            </div>    
        </div>    
        <payment-description/>
    </div>
</template>

<script>
    import PaymentDescription from  '../../components/payments/Description';

    import sandbox from '../../components/payments/gateways/Sandbox';
    import cardstream from '../../components/payments/gateways/Cardstream';
    import transacteuro from '../../components/payments/gateways/Transacteuro';
    import konntransacteuro from '../../components/payments/gateways/Konntransacteuro';
    import konndummy from '../../components/payments/gateways/Konndummy';
    import dalenys from '../../components/payments/gateways/Dalenys';
    import payxpert from '../../components/payments/gateways/Payxpert';
    import konnpaay from '../../components/payments/gateways/Konnpaay';
    import emerchantpay from '../../components/payments/gateways/Emerchantpay';
    import konncomputop from '../../components/payments/gateways/Konncomputop';
    import konnemerchantpay from '../../components/payments/gateways/Konnemerchantpay';

    export default {
        layout: 'dashboard',
        components: {
            PaymentDescription,
            sandbox,
            cardstream,
            transacteuro,
            konntransacteuro,
            konndummy,
            dalenys,
            payxpert,
            konnpaay,
            emerchantpay,
            konncomputop,
            konnemerchantpay,
        },

        computed:{
            gateway(){
                return this.$payment.info.data.gateway.key;
            }
        },
    }
</script>

