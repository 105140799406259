<template>
    <payment 
        ref="payment" 
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        >
            <template v-slot:payment_data>
                <main class="col-12 col-lg-8 col-md-12 mb-md-3">
                    <intergiro-card-input class="input" api-key="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5wYXlmdW5jLmNvbSIsImlhdCI6MTY2MDIyMDg3MSwibmFtZSI6IktQSFMgU0lBIiwidHlwZSI6ImxpdmUiLCJzdWIiOiJWQW16RG9lQiIsImF1ZCI6InB1YmxpYyIsImZlYXR1cmVzIjoiIiwiY2FyZCI6eyJ1cmwiOiJodHRwczovL2FwaS5wYXlmdW5jLmNvbSIsImNvdW50cnkiOiJTRSIsImRlc2NyaXB0b3IiOiJodHRwczovL2dlb2NlbGwubW9iaS8iLCJlbXYzZCI6IjdBSkNnQlItVG1Xci1oX3ZId1hHOHhqaEtQbm4tblJxYWhGU1dXcnc3ZlJXWGNHOThqeEVmc2xhRDVZenh4ekJSZVBSbDBkNEVKRnFCUy02Zi1zdGtUQmxaVGRtTWpSa0xUWTNOakF0TkRsaFpDMDVNREl6TFRNNE9XWmpOakUxTkRWaU9TSjlMSHNpY0hKdmRHOWpiMndpT2lKamFETmtNU0lzSW5WeWJDSTZJbWgwZEhCek9pOHZiWEJwTGpOa2MyVmpkWEpsTG1sdklpd2lhMlY1SWpvaVpHWmxaVFV4TXpjdE0yVTBZUzAwT1dOaUxUZzNPREV0WlROaVlURmtOMlF5TVdJNUluMWQiLCJhY3F1aXJlciI6InVPazFYbkdsOFljanNDeHVrVHN1enAtYnZfQUpOR2hyMDhLNnprSkEweWtyMG9jYlBJTlNWc0lJV1hab3FaVDQwQjBnX0c0NnhjcFhpN1ppNThpQ0czVnVZeTVqYjIwaUxDSmlhVzRpT25zaWJXRnpkR1Z5WTJGeVpDSTZJakkzTURVM01pSXNJblpwYzJFaU9pSTBOak16TmpVaWZYMCIsIm1pZCI6IjgxMDMwMDAwMDAyOSIsIm1jYyI6IjczNzUifSwiYWdlbnQiOiJpbnRlcmdpcm8tcHNwIiwiaWQiOiJWQW16RG9lQiIsInVybCI6Imh0dHBzOi8vd3d3LmV4YW1wbGUuY29tIn0.O_ZPZgCBuO2skpM_M8UA1L8q8rK9AF8cPA1ooTmFoPE" 
                    cosmetic='{
                    "header": { "background": "134, 146, 166", "color": "0, 32, 84" },
                    "text": { "background": "255, 255, 255", "color": "0, 32, 84" },
                    "submit": { "background": "112, 145, 124", "color": "212, 213, 214" },
                    "border": { "color": "134, 146, 166" },
                    "font_family": "Ubuntu",
                    "danger_color": "131, 21, 3"}'></intergiro-card-input>
                    <p v-show="card_error.length" data-v-5f4ccefe="" class="error" style="padding-top:10px;color: red;">
                        {{card_error}}
                    </p>            
                </main>

                <div class="col-12 col-lg-8 col-md-12 mb-md-3">
                        <label>{{ $translate('card_holder') }}:</label>
                        <Field
                            class="form-control"
                            name="card_holder" 
                            rules="required"
                            v-model="cardholder"
                            :placeholder="$translate('card_holder')"
                        />
                        <ErrorMessage name="card_holder" as="error" v-slot={message}> 
                            Card holder is not valid 
                        </ErrorMessage>  
                </div>   
                
            </template>   
            <template v-slot:submit-button>
                <button v-show="isProcessing==false"
                            class="btn btn-pay"
                            style="width: 100%;"
                            id="btn-pay"
                        >
                            {{ $translate('pay_now') }}
                </button>  
                <div v-show="isProcessing==true"><div class="lds-ring"><div></div><div></div><div></div><div></div></div> &nbsp;<div>Processing,<br>please wait ...</div></div>
            </template> 
        </payment-form>
          
    </payment>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import PaymentForm from '../../forms/PaymentForm';
import Payment from '../PaymentContainer';

export default {
    components: {
        PaymentForm, 
        Payment, 
        Field, 
        Form,
        ErrorMessage
    }, 
    props: {
       
    },
    data() {
        return {
            isProcessing: false,
            errors: [],
            iframeData: {},
            cardholder: '',
            email: '',
            phone_number: '',
            phone_code: '',
            card_error:'',           
            validationErrors: {},
            data: {},
            fastRegisterResponse: null
        }
    },

    watch: {
        '$store.currency' : function(){
            this.loadIntergiro();
        }
    },

    computed: {
        currency(){
            return this.$store.currency;
        }
    },
    methods: {
        registerError(data){
            this.errors = data;    
        },

        pending(data){
            //let result = window.wpwl.executePayment('wpwl-container-card');
            console.log('pending');
        },

        submitForm(data){
            this.email=data.email
            this.phone_number=data.phone
            this.phone_code=data.phoneCode
            this.card_error='';
            this.isProcessing=true;
            this.create()

        },
        async  create(customer, card, error) {
            
            const element = document.querySelector("intergiro-card-input")
            card = await element.submit(card, error)
                 
            let result
            if (typeof card == "string") {
				customer = customer ?? { 
                    number:'geocell.mobi '+ (Math.random() * 100000).toString(),
                    method: [{ 
                        type: "token",
                        client: {
                            "browser": { 
                                "javascript": true,
                                "java": navigator.javaEnabled(),
                                "colorDepth": screen.colorDepth,
                                "locale": navigator.language,
                                "timezone": new Date().getTimezoneOffset(),
                                "resolution": [screen.width, screen.height]           
                            } 
                        } 
                    }],
                    contact:{name:this.cardholder,email:this.email},
                    order:{ 
                        items: [{
                            name:'geocell.mobi trial',
                            price:Number(this.$payment.getAmount().toFixed(2)),
                            quantity:1
                        }],
                        currency: this.currency                                       
                    },
                }
				customer.method[0].card = card
               

                const response = await fetch("https://merchant.intergiro.com/v1/customer", {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5wYXlmdW5jLmNvbSIsImlhdCI6MTY2MDIyMDg3MSwibmFtZSI6IktQSFMgU0lBIiwidHlwZSI6ImxpdmUiLCJzdWIiOiJWQW16RG9lQiIsImF1ZCI6InByaXZhdGUiLCJmZWF0dXJlcyI6IiIsImNhcmQiOnsidXJsIjoiaHR0cHM6Ly9hcGkucGF5ZnVuYy5jb20iLCJjb3VudHJ5IjoiU0UiLCJkZXNjcmlwdG9yIjoiaHR0cHM6Ly9nZW9jZWxsLm1vYmkvIiwiZW12M2QiOiI3QUpDZ0JSLVRtV3ItaF92SHdYRzh4amhLUG5uLW5ScWFoRlNXV3J3N2ZSV1hjRzk4anhFZnNsYUQ1WXp4eHpCUmVQUmwwZDRFSkZxQlMtNmYtc3RrVEJsWlRkbU1qUmtMVFkzTmpBdE5EbGhaQzA1TURJekxUTTRPV1pqTmpFMU5EVmlPU0o5TEhzaWNISnZkRzlqYjJ3aU9pSmphRE5rTVNJc0luVnliQ0k2SW1oMGRIQnpPaTh2YlhCcExqTmtjMlZqZFhKbExtbHZJaXdpYTJWNUlqb2laR1psWlRVeE16Y3RNMlUwWVMwME9XTmlMVGczT0RFdFpUTmlZVEZrTjJReU1XSTVJbjFkIiwiYWNxdWlyZXIiOiJ1T2sxWG5HbDhZY2pzQ3h1a1RzdXpwLWJ2X0FKTkdocjA4SzZ6a0pBMHlrcjBvY2JQSU5TVnNJSVdYWm9xWlQ0MEIwZ19HNDZ4Y3BYaTdaaTU4aUNHM1Z1WXk1amIyMGlMQ0ppYVc0aU9uc2liV0Z6ZEdWeVkyRnlaQ0k2SWpJM01EVTNNaUlzSW5acGMyRWlPaUkwTmpNek5qVWlmWDAiLCJtaWQiOiI4MTAzMDAwMDAwMjkiLCJtY2MiOiI3Mzc1In0sImFnZW50IjoiaW50ZXJnaXJvLXBzcCIsImlkIjoiVkFtekRvZUIiLCJ1cmwiOiJodHRwczovL3d3dy5leGFtcGxlLmNvbSJ9.IThm-mrHNgN-xJ1jWiaypU1LGiwnJQoTFJH6RW5tA40",
                    },
                    method: "POST",
                    body: JSON.stringify(customer),
                })
                result = response.headers.get("content-type").startsWith("application/json")
                    ? await response.json()
                    : await response.text()
                if (response.ok == true) {
                    // check if user exists
                    if (this.fastRegisterResponse === null ) {
                        this.fastRegisterResponse = await this.fastRegister()
                    }
                    if (this.fastRegisterResponse.data.data.user_id !== 'undefined') {
                        this.createRegistrationPayment(result)
                    } 
                } else {
                    result = this.create(customer, card, result);
                }
            } else {
                if (card.content.description === "3D Secure failed") {
                    this.$payment.failPayment();
                } else {
                    // this.card_error=card.content.description;
                    this.card_error="Invalid card data";
                    this.isProcessing=false;
                    result = card
                }
            }
            return result
        },
        async fastRegister () {
            return await axios.post('/user/fastRegister', {
                name: this.cardholder,
                email: this.email,
                password: '',
                phone_code: this.phone_code,
                phone_number: this.phone_number,
                payment_form: true,
                currency: this.currency,
            }).then((response) => {
                if(response.data.result == 'success'){
                    return response;
                }
                this.$emit('register_error', response.data.data);
            }).catch(error => {
                this.$emit('register_error', error.response.data.data);
                this.isProcessing = false;
            });
        },        
        createRegistrationPayment (result) {
            axios.post(`/payments/complete/intergiro`, {
                transaction_result:result,
                locale: this.$store.locale,
                currency: this.currency
            }).then((resp) => {
                console.log(resp);
                if(resp.data.result == 'success'){
                    this.$payment.successPayment();
                }else{
                    this.$payment.failPayment();
                }   
            }).catch(error => {
                this.isProcessing = false;
                this.$payment.failPayment();
                
            });
        },        
        loadIntergiro(){
            let intergiroScript = document.createElement('script')
            intergiroScript.setAttribute('type', 'module')
            intergiroScript.setAttribute('src', 'https://merchant.intergiro.com/intergiro-ui.esm.js')
            document.head.appendChild(intergiroScript)
            var intergiroCss=document.createElement("link")
            intergiroCss.setAttribute("rel", "stylesheet")
            intergiroCss.setAttribute("type", "text/css")
            intergiroCss.setAttribute("href", 'https://theme.payfunc.com/intergiro/variables.css')
        }
    },
    mounted(){
        this.loadIntergiro()
    },
    
}
</script>

<style>
    .wpwl-form-card,
    .wpwl-container-card{
        width: 100%;
    }

    .wpwl-form-card{
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        
    }
    .wpwl-form {
        max-width: 100%;
        margin-bottom: 0;
        padding: 0; 
    }

    .wpwl-group-brand{
        display: none;
    }

    .wpwl-group-submit{
        display: none;
    }

    .wpwl-button-pay {
        float: left;
        background: #00D98B;
        border-radius: 5px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        width: 300px;
        min-height: 48px;
        outline: none;
        border: none transparent;
    }

    .wpwl-button-pay:hover{
        background: #00cc81;
    }

    .wpwl-button:focus, 
    .wpwl-button:active,
    .wpwl-button:active:focus{
        background: #00cc81;
        outline: none !important;
    }

    .wpwl-label{
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #001F72;
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .wpwl-control{
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }

    .wpwl-has-error~.wpwl-hint {
        color: red;

    }
    .spinner{
        z-index: 500 !important;
    }

    .lds-ring {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #000;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #000 transparent transparent transparent;
        }
        .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
        @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        }
</style>