<template>
    <payment 
        ref="payment" 
        @pending="pending"   
        @register_error="registerError"
    >
        <payment-form 
            :internalErrors="errors"
            @submitForm="submitForm"
        >
            <template v-slot:payment_data>
                <div id="transacteuro-form">
                   
                </div>    
            </template>    
        </payment-form>
        <teleport to="body">
            <IframeModal 
                url="/payments/iframes/transacteuro"
                :data="iframeData"
                @hidden="loadTransact"
            />
        </teleport>    
    </payment>
</template>

<script>

import PaymentForm from '../../forms/PaymentForm';
import IframeModal from '../../modals/IframeModal';
import Payment from '../PaymentContainer';

export default {
    components: {
        PaymentForm, Payment, IframeModal
    }, 
    props: {
       
    },
    data() {
        return {
            isProcessing: false,
            errors: [],
            data: {},
            checkoutToken: '',
            iframeData: {}
        }
    },

    watch: {
        '$store.currency' : function(){
            this.loadTransact();
        }
    },

    computed: {
        currency(){
            return this.$store.currency;
        },
        redirectUrl(){
            return location.origin + '/payments/complete/transacteuro';
        }
    },
    methods: {
        registerError(data){
            this.errors = data;    
        },

        pending(data){
            let result = window.wpwl.executePayment('wpwl-container-card');
        },

        submitForm(data){
            data.cardholder = "_";
            data.cvv = "_";
            data.expiry = "00/00";
            data.card_number = "_";
            data.checkout_token = this.checkoutToken;
            this.$refs.payment.processPayment(data);
        },

        loadTransact(){
            this.$refs.payment.isProcessing = true;
            axios.post('/payments/checkout/transacteuro', {
                currency: this.currency,
                locale: this.$store.locale,
                country: this.$store.phone_iso,
                email: this.$store.email
            }).then(resp => {
                if(resp.data.result == 'success'){
                    if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
                        window.wpwl.unload();
                        document.getElementById('transacteuro_script').remove()
                    }
                    this.checkoutToken = resp.data.data.ndc;

                    let app = this;
                    window.wpwlOptions = {
                        paymentTarget: 'threeDSFrame',
                        shopperResultTarget: 'threeDSFrame',
                        onBeforeSubmitCard: function(event){
                            app.iframeData = {ready: 1};
                            return true;
                        },
                        locale: app.$store.locale,
                    }
                    
                    let form = document.createElement('form');
                    form.setAttribute('action', this.redirectUrl);
                    form.setAttribute('data-brands', "VISA MASTER AMEX");
                    form.setAttribute('class', "paymentWidgets payment-transact");
                    document.getElementById('transacteuro-form').innerHTML = '';
                    document.getElementById('transacteuro-form').appendChild(form);
                    let script = document.createElement('script');
                    script.setAttribute('id', 'transacteuro_script');
                    script.setAttribute('src', 'https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=' + this.checkoutToken);
                    document.body.appendChild(script);
                }
                this.$refs.payment.isProcessing = false;
            }).catch((error) => {
                this.$refs.payment.isProcessing = false;
            })
        }
    },
    mounted(){
        this.loadTransact();
    },
    
}
</script>

<style>
    .wpwl-form-card,
    .wpwl-container-card{
        width: 100%;
    }

    .wpwl-form-card{
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        
    }
    .wpwl-form {
        max-width: 100%;
        margin-bottom: 0;
        padding: 0; 
    }

    .wpwl-group-brand{
        display: none;
    }

    .wpwl-group-submit{
        display: none;
    }

    .wpwl-button-pay {
        float: left;
        background: #00D98B;
        border-radius: 5px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        width: 300px;
        min-height: 48px;
        outline: none;
        border: none transparent;
    }

    .wpwl-button-pay:hover{
        background: #00cc81;
    }

    .wpwl-button:focus, 
    .wpwl-button:active,
    .wpwl-button:active:focus{
        background: #00cc81;
        outline: none !important;
    }

    .wpwl-label{
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #001F72;
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .wpwl-control{
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }

    .wpwl-has-error~.wpwl-hint {
        color: red;

    }
    .spinner{
        z-index: 500 !important;
    }
</style>