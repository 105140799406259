<template>
    <div class="container page-content forgot-password-content">
        <h1>{{ $translate('forgot_pw') }}</h1>
        <div class="box-content mt-4 col-md-6 col-12">
            <Form ref="forgotPasswordForm" @submit="onSubmit">
                <div class="mb-3">
                    <Field
                        v-model="email"
                        :placeholder="$translate('enter_email_to_reset')"
                        class="form-control"
                        rules="required|email"
                        name="email"
                    />
                    <error-message name="email" as="error"/>
                </div>  
                <button class="btn btn-success">
                    {{ $translate('reset') }}
                </button>
            </Form>    
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                email: '',
            }
        },
        methods: {
            onSubmit () {
                axios.post(`/user/forgotPasswordRequest`, {
                    email: this.email
                }).then((res) => {
                    if (res.data.result == 'success') {
                        this.$modals.notification('Password reset link successfully sent to your email.');
                        return;
                    } 
                }).catch((error) => {
                    this.$refs.forgotPasswordForm.setErrors(error.response.data.data);
                })
            },
        }
    }
</script>

