<template>
    <modal-base 
        id="threeDS-modal" 
        dialogClass="modal-dialog-centered modal-lg"
        :hideFooter="true"
        title="3D Secure"
    >

        <div>
            <div class="loader-wrapper" id="loader" v-if="isProccesing">
                <div class="loader"></div>
            </div>
            <iframe 
                id="threeDSFrame"
                ref="threeDSFrame" 
                frameborder="0"
                style="width: 100%; height: 100%; min-height: 50vh;"
                name="threeDSFrame"
                :src="url"
            />
        </div>
    </modal-base>
</template>

<script>
    export default {
        props: {
            url: {
               type: String,
               default: ''
            },
            data: {
                type: Object,
                default: () => {}
            },
            isProccesing: {
                type: Boolean,
                default: false 
            }
        },
 
        watch: {
            data(){
                this.sendData();
            }
        },

        mounted(){
           
            window.addEventListener('message', (event) => {
                if(!event.data.payment_result){
                    return;
                }
                if(event.data.payment_result.result == 'fail'){
                    this.$modals.hide('threeDS-modal');
                    if(event.data.payment_result.message == 'Please enter existing email address'){
                        this.$modals.notification('PLEASE ENTER VALID EMAIL ADDRESS');
                        return;
                    }
                    this.$payment.failPayment();
                    return;
                }
                if(event.data.payment_result.result == 'success'){
                    this.$modals.hide('threeDS-modal');
                    this.$payment.successPayment();
                    return;
                }
            });
        },
        methods: {
            sendData(){
                document.getElementById('threeDSFrame').setAttribute('src', this.url);
                this.$modals.show('threeDS-modal');                
                setTimeout(() => {window.frames.threeDSFrame.postMessage({data: JSON.parse(JSON.stringify(this.data))}, '*');}, 500);
            }
        }
    }

</script>
<style>
    #threeDS-modal .modal-body {
        padding: 0 !important;
    }

    #threeDS-modal .container {
        padding: 0 !important;
    }

    #threeDS-modal .loader-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
        z-index: 1000;
        background: #fff;
    }
</style>

