<template>
    <div class="modal fade" :id="id" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog" :class="dialogClass">
            <div class="modal-content">
                <div class="modal-header" v-if="!hideHeader">
                    <h5 class="modal-title"> 
                        {{title}}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer" v-if="!hideFooter">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props: {
            id: {
                type: String,
                default: ''
            },
            title: {
                type: String,
                default: '',
            },
            dialogClass: {
                type: String,
                default: ''
            },
            hideHeader: {
                type: Boolean,
                default: false
            },
            hideFooter: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {};
        },

        methods: {
        },
        mounted(){
            document.getElementById(this.id).addEventListener('shown.bs.modal', event => {
                this.$emit('shown');
            })

            document.getElementById(this.id).addEventListener('hidden.bs.modal', event => {
                this.$emit('hidden');
            })
        }
    }
</script>