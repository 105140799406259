<template>
    <section class="map">
        <Map/>

        <Form class="main-header-form map-phone-container" @submit="onSubmit" ref="locateForm">
            <div class="phone-locate-container">
                <phone-code :showCountryName="false"/>
                <Field 
                    name="phone"
                    class="form-control" 
                    :placeholder="$translate('enter_phone_number')"
                    v-model="locatingNumber"
                />          
            </div>  
            <button class="btn btn-success btn-locate">
                {{ $translate('locate') }}
            </button>
        </Form>
        <teleport to="body">
            <locate-modal :locatingNumber="locatingNumber" :notifyCode="$store.phone_code"/>
        </teleport>
    </section>    
</template>

<script>
    import Map from '../../components/Map';    
    import PhoneCode from '../../components/forms/PhoneCode';
    import { Field, Form } from 'vee-validate';    
    import LocateModal from '../../components/modals/LocateModal';

    export default {
        components: {
            Map,  PhoneCode, Field, Form, LocateModal
        },

        data: () => ({
            locatingNumber: '',
        }),

        mounted(){
        },

        computed: {
            user() {
                return this.$auth.user.data;
            },
        },

        methods:{
            openModal(){
                
            },

            onSubmit(){
                if (typeof this.locatingNumber === "undefined" || this.locatingNumber.length < 8) {
                    this.$modals.notification(this.$translate('too_short'))
                    return;
                } 
                if (this.locatingNumber.length > 15) {
                    this.$modals.notification("Number must not be more than 15 digits!");
                    return;
                } 
                if (this.locatingNumber.match(/[\D]/)) {
                    this.$modals.notification("Number must contain only digits!");
                    return;
                } 
                this.$modals.show('locate-modal');
            },
            
        }
    }
</script>