<template>
    <modal-base 
        id="notification-modal" 
        title="Notification"
        dialogClass="notification-modal"
        :hideHeader="true"
    >
        <template v-slot:footer>
            <button  class="btn btn-primary" data-bs-dismiss="modal">
                OK
            </button>
        </template>
    </modal-base>    
</template>

export default {

}