<template>
    <div class="sidebar">
        <ul>
            <li class="logo">
                <router-link to="/">
                    <img :src="logo">
                </router-link>    
            </li>
            <li class="icon-img">
                <router-link to="/dashboard/locate">
                    <img src="/img/dashboard/dashboard-icon/address.svg">
                </router-link>    
            </li>
            <li class="icon-img">
                <router-link to="/dashboard/locateList">
                    <img src="/img/dashboard/dashboard-icon/track.svg">
                </router-link>
            </li>
            <li class="icon-img">
                <router-link to="/dashboard/subscriptionHistory">
                    <img src="/img/dashboard/dashboard-icon/file.svg">
                </router-link>    
            </li>
            <li class="icon-img">
                <a href="#" data-bs-toggle="modal" data-bs-target="#logout-modal">
                    <img src="/img/dashboard/dashboard-icon/open-lock.svg">
                </a>
            </li>
            <li class="icon-img">
                <span class="langSelectorSpan">
                    <select 
                        id="langSelectId"
                        v-model="$store.locale"
                    >
                        <option
                            v-for="(value, key) in $config('locales')"
                            :value="key"
                        >
                            {{key}}
                        </option>
                    </select>
                </span>
            </li>
        </ul>
        <teleport to="body">
            <modal-logout/>
        </teleport>
    </div>
</template>

<script>
    import ModalLogout from '../modals/LogoutModal';

    export default{
        name: 'sidebar',
        components: {ModalLogout},
        data(){
            return {
                currentLang: '',
            };
        },
        mounted(){
            this.currentLang = this.locale;
        },
        computed: {
            logo(){
                return window.config.dashboard_logo;
            },
            locales(){
               // return this.$store.state.lang.locales;
            },
            locale(){
               // return this.$store.state.lang.locale
            }
        },

        methods:{
            setLocale () {
                if (this.$i18n.locale !== this.currentLang) {
                    loadMessages(this.currentLang);
                    this.$store.dispatch('lang/setLocale', this.currentLang);
                }
            },
        }
        
    }

</script>