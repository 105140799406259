import { reactive} from "vue";

let params = {
    locale: window.config.default_geo.locale,
    currency: window.config.default_geo.currency,
    phone_code: '',
    phone_iso: window.config.default_geo.iso_code,
    phone_number: '' 
};

const state = reactive(
    Object.assign(params, JSON.parse( localStorage.getItem('store') || '{}'))
);

export default {
    install: (app, options) => {
        app.config.globalProperties.$store = state;
    }
}
