import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'en',
    allowComposition: true, 
    messages: {},
    warnHtmlInMessage: 'off',
    escapeParameterHtml: false,
    
});

export async function loadMessages (locale) {
    if (Object.keys(i18n.global.getLocaleMessage(locale)).length === 0) {
       // const messages = require(`../../../../../storage/app/public/langs/${locale}.json`);
       // i18n.global.setLocaleMessage(locale, messages);
        await axios.get(`/storage/langs/${locale}.json`)
                .then(response => {
                    i18n.global.setLocaleMessage(locale, response.data);
                });
        
    }
    if (i18n.global.locale !== locale) {
        i18n.global.locale = locale;
    }
}
    
export default {
    install: (app, options) => {
        app.use(i18n);
        (async function () {
            await loadMessages(app.config.globalProperties.$store.locale);
        })();
        
        app.config.globalProperties.$translate = (key) => {
            if(!i18n.global.te(key)){
                return key;
            }
            let config = window.config;
            let properties = app.config.globalProperties;
            let params = {
                domain: config.domain,
                app_name: config.app_name,
                company_name: config.company_name,
                company: config.company_name,
                address: config.company_address,
                email: config.company_email,
                phone: config.company_phone,
                phone_link: `<a href="tel:${config.company_phone}">${config.company_phone}</a>`,
                email_link: `<a href="mailto:${config.company_email}">${config.company_email}</a>`,
                company_number: config.company_number,
                currency: properties.$store.currency,   
                monthlyAmount: properties.$payment.getUsualAmount().toFixed(2),
                duringDays: properties.$payment.getDuration(),
                usualDuration: properties.$payment.getUsualType(),
                trialDays: properties.$payment.getTrialDuration(),
                weeklyAmount: properties.$payment.getTrialAmount().toFixed(2),  
            }; 
            return i18n.global.t(key, params);
        };
    }
}
   
