<template>
    <div>
        <a class="navbar-brand" href="/">
            <img :src="$config('logo')" class="logo">    
        </a>    
    </div>
</template>

<script>
export default {
}
</script>

