<template>
    <div>
        <div class="fixed-header">
            <div class="container">
                <topbar-component/>
            </div>    
        </div>    
        <slot/>
        <footer-component/>
    </div>
</template>

<script>
    import FooterComponent from './../components/Footer'
    import TopbarComponent from './../components/landing/TopBar';

    export default {
        components: {
            FooterComponent, TopbarComponent
        }
    }
</script>
