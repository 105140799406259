<template>
    <div class="container page-content faq-content">
        <div class="row align-items-start">
            <div class="col-md-12">
                <h1>{{ $translate('frequently_asked_questions') }}</h1>
                <div class="accordion" id="accordion-faq">
                    <div class="accordion-item" v-for="(q, key) in faq" :key="key">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#${key}`">
                                <i class="fa fa-file-text-o"/><span v-html="$translate(key)"></span>
                            </button>
                        </h2>
                        <div :id="key" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordion-faq">
                            <div class="accordion-body">
                                <p v-html="$translate(q.answer)"></p>
                                <a href="#" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#login-modal" v-if="q.login">Login</a>
                            </div>
                        </div>
                    </div>    
                </div>    
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    computed: {
        faq: {
          get () {
            return {
                q_is_it_legal: {
                    answer: 'a_is_it_legal',
                },
                q_what_phones: {
                    answer: 'a_what_phones',
                },
                q_how_accurate: {
                    answer: 'a_how_accurate',
                },
                q_how_long: {
                    answer: 'a_how_long',
                },
                q_will_work: {
                    answer: 'a_will_work',
                },
                q_how_much: {
                    answer: 'a_how_much',
                },
                q_is_safe: {
                    answer: 'a_is_safe'
                },
                q_how_cancel: {
                    answer: 'a_how_cancel',
                    login: true,
                },
                q_how_reset: {
                    answer: 'a_how_reset',
                    login: true,
                }
            }
          }
        },
    }
}
</script>
