<template>
    <payment 
        ref="payment"
        @pending="pending"   
        @register_error="registerError"
    >
        <form id="cardstream-form">
            <payment-form 
                :internalErrors="errors"
                @submitForm="submitForm"
            >
            </payment-form>
            <input type="hidden" >
        </form>    
       
        <teleport to="body">
            <IframeModal 
                url="/payments/iframes/cardstream"
                :data="iframeData"
                :isProccesing="isProccesing"
            />
        </teleport>    
    </payment>
</template>

<script>

    import PaymentForm from '../../forms/PaymentForm';
    import IframeModal from '../../modals/IframeModal';
    import Payment from '../PaymentContainer';

    export default {
        components: {
            PaymentForm, Payment, IframeModal
        }, 
        data() {
            return {
                errors: [],
                iframeData: {},
                isProccesing: false,
            }
        },
        mounted(){
            //this.loadCardstream();

            window.addEventListener('message', (event) => {
                //console.log(event);
            });
        },
        methods: {
            registerError(data){
                this.errors = data;    
            },

            submitForm(data){
              //  document.getElementById('cardstream-form').submit();
                data.browserInfo = this.getBrowserData();
                this.$refs.payment.processPayment(data);
            },

            pending(data){
                this.iframeData = data.data;
                this.isProccesing = true;
                setTimeout(()=>{this.isProccesing = false}, 5000 );
            },

            loadCardstream(){
                let script = document.createElement('script');
                script.setAttribute('id', 'cardstream_script');
                script.setAttribute('src', 'https://gateway.cardstream.com/sdk/web/v1/js/hostedforms.min.js');
                document.body.appendChild(script);
                setTimeout(() => {
                    
                    let form = new window.hostedForms.classes.Form(document.getElementById('cardstream-form'),{
                        autoSetup: true,
                        autoSubmit: true,
                        tokenise: ".add-totoken",
                        stylesheets:"#hostedfieldstylesheet",
                        fields:{
                            any:{"nativeEvents":true},
                            cardNumber:{
                                selector: "[data-threeds=pan]",
                            },
                            
                        },
                        "classes":{"invalid":"error"}
                    });  
                   // console.log(window.hostedForms.forms[0].validate());
                }, 2000);
            },
            getBrowserData() {
                const navigator = window.navigator;
                return {
                    browserAcceptHeader: 'application/json',
                    browserUserAgent: navigator.userAgent,
                    browserLanguage: navigator.language,
                    browserTimeZone: (new Date()).getTimezoneOffset().toString(),
                    browserScreenColourDepth: screen.colorDepth,
                    browserScreenHeight: screen.height.toString(),
                    browserScreenWidth: screen.width.toString(),
                };
            },
        }
    }
</script>