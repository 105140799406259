export default async function subscription ({ next, app }){    
    if(!app.config.globalProperties.$auth.user.data){    
        await app.config.globalProperties.$auth.check();
    } 
    if(!app.config.globalProperties.$auth.user.data){
        return next({name: 'main'});
    }else if(!app.config.globalProperties.$auth.user.data.is_active){
        return next({
            name: 'dashboardPayment'
        })
    }else{  
        return next();
    }
}