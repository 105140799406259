<template>
    <div class="container contact-content">
        <div class="row align-items-start">
            <div class="col-md-12">
                <div class="section-title">
                    <h2>{{ $translate('contact_page_title') }}</h2>
                </div>
            </div>
            <div class="col-md-8">
                <div class="faq-box mt-4">
                    <div v-html="$translate('contact_us_text_1')"></div>
                </div>
            </div>
        </div>
 
        <div class="row">
            <div class="col-md-8">
                <div class="faq-box mt-4">
                    <div>
                        <div style="display: inline-block; width: 24px; margin-right: 8px;">
                            <img class="img-mail" src="/img/front/icons/mail.png" alt="Email" style="background: #ccc; padding: 2px;">
                        </div>
                        {{ $config('company_email') }}
                    </div>
                    <div class="mb-3">
                        <div style="display: inline-block; width: 24px; margin-right: 8px;">
                            <img class="img-phone" src="/img/front/icons/phone.svg" alt="Phone" style="background: #ccc; padding: 2px;">
                        </div>
                        {{ $config('company_phone') }}
                    </div>
                    <div v-html="$translate('contact_us_text_2')"></div>
                </div>
            </div>
        </div>
    </div>
</template>

