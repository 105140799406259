<template>
    <section class="main mb-3">
       
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12">
            <div class="track-list">
                <ul>
                    <li class="locating" v-if="!locatingHistory.length">
                        <a href="#">
                          <b>History is clear</b><br>
                          <span class="time-zone">&nbsp;</span>
                        </a>
                        <label class="denied"> none </label>
                    </li>
                    <li
                        v-for="(location, index) in locatingHistory"
                        :id="location.id"
                        :key="`location-${index}`"
                        class="locating"
                        @click="setActiveLocation(location)"
                    >
                        <a :href="location.status === 'success' ? '#map' : '#'">
                            <b>+{{ location.country_code }} {{ location.mobile_number }}</b><br>
                            <span class="time-zone">
                                {{ location.dt_created }}
                            </span>
                        </a>

                        <label  v-if="location.status === 'pending'" class="pending">
                            {{ $translate('pending') }}
                        </label>
                        <label
                            v-else-if="location.status === 'success'"
                            :id="location.id"
                            class="located"
                        >
                            {{ $translate('located') }}
                        </label>
                        <label v-else-if="location.status === 'rejected'"  class="denied">
                            {{ $translate('denied') }}
                        </label>
                        <label v-else class="not_delivered">
                            {{ $translate('not_delivered') }}
                        </label>
                    </li>
                </ul>
            </div>
        </div>
        <div  class="col-lg-8 col-md-7 col-sm-12 col-xl-8" style="height: 90vh;" >
            <div style="height: 100%; padding:15px; background-color: #fff;" id="map">
                <Map :location="activeLocation"/>
            </div>
        </div>
      </div>
    </section>    
</template>

<script>
    import Map from '../../components/Map';    
   
    export default {
        components: {
           Map
        },

        data: () => ({
            locatingHistory: [],
            activeLocation: {}
        }),

        mounted(){
            this.getLocates();
        },

        methods: {
            setActiveLocation(location) {
                if (location.latitude !== null) {
                    this.activeLocation = location
                    this.activeLocation.position = {
                        lat: parseFloat(location.latitude),
                        lng: parseFloat(location.longitude)
                    }
                }
            },
            getLocates(){
                axios.get(`/locates/user_locates`)
                    .then((response) => {
                        this.locatingHistory = _.orderBy(response.data.data, 'id', 'desc');
                    });
            }
        }
    }
</script>
