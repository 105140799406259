<template>
    <div class="navbar navbar-light navbar-expand-lg justify-content-between landing-top-navbar">
        <Logo />
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-top">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbar-top" class="collapse navbar-collapse">
            <div class="navbar-nav ml-auto mt-2 mt-lg-0">
                <a v-if="user" href="#" @click="$router.push('/dashboard')" class="btn btn-outline-secondary me-lg-4 mb-lg-0 mb-2">
                    {{$translate('dashboard')}} 
                </a>
                <a v-if="!user"  class="btn btn-outline-register me-lg-4 mb-lg-0 mb-2" data-bs-toggle="modal" data-bs-target="#register-modal">
                    {{$translate('register')}}
                </a>
                <a v-if="!user" class="btn btn-outline-primary mb-lg-0 mb-2"  data-bs-toggle="modal" data-bs-target="#login-modal">
                    {{$translate('login')}} {{$auth.user.id}}
                </a>
                <a v-if="user" href="#" @click="$auth.logout()" class="btn btn-outline-danger">
                    {{$translate('logout')}}
                </a>
            </div>    
        </div>  

        <teleport to="body">
            <register-modal/>
        </teleport>
        <teleport to="body">
            <login-modal/>
        </teleport>
    </div> 
    
</template> 

<script>
import Logo from '../Logo';
import RegisterModal from '../modals/RegisterModal';
import LoginModal from '../modals/LoginModal';

export default {
    components: { Logo, RegisterModal, LoginModal },
    computed: {
        user() {
            return this.$auth.user.data || null;
        }
    },
}
</script>
