<template>
    <modal-base 
        id="locate-modal" 
        title="Locate Mobile"
        :hideFooter="true"
        @shown="showModal"
    >
        <div class="container">
            <div class="text-center mb-4">
                <img
                    src="/img/dashboard/icon-logo.png"
                    class="locate-logo mb-2"
                >
                <h6 class="text-center text-dark mb-2">
                    Locating
                </h6>

                <h4 class="text-primary">
                    +{{ locatingNumberCode }}
                    {{ locatingNumber }}
                </h4>
            </div>   

            <Form @submit="onSubmit" ref="locateForm">
                <div class="row mb-2">
                    <div class="col-md-4 mb-1">
                        <phone-code 
                            @update:phone-code="updateCountryCode" 
                            :showCountryName="false" 
                            :isUpdateStore="false"
                        />
                    </div>
                    <div class="col-md-8">
                        <Field
                            class="form-control"
                            v-model="notificationNumber"
                            :placeholder="$translate('locate_modal_notify_me')"
                            name="notificationNumber"
                            rules="required"
                        />
                        <ErrorMessage name="notificationNumber" as="error" v-slot={message}> 
                            Notification phone is required   
                        </ErrorMessage> 
                    </div>
                </div>    

                <div class="mb-2">
                    <div class="predefined-drp text-end">
                        <div class="dropdown">
                            <button
                                class="btn-white-sm dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                            >
                                Predefined
                            </button>
                            <div  class="dropdown-menu dropdown-menu-right">
                                <a
                                    v-for="(text, index) in predefinedTexts"
                                    :key="`predefinedText-${index}`"
                                    class="dropdown-item"
                                    @click="currentText=text"
                                    href="#"
                                >{{ text }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="mb-4">
                    <Field
                        class="form-control"
                        v-model="currentText"
                        placeholder="Message *"
                        name="currentText"
                        as="textarea"
                        rules="required"
                        rows="4"
                    />
                    <ErrorMessage name="currentText" as="error" v-slot={message}> 
                        Text message is required    
                    </ErrorMessage>  
                </div>

                <button class="btn btn-primary modal-submit">
                    {{ $translate('locate') }}
                </button>
       
            </Form>
        </div>
    </modal-base>    
</template>

<script>
    
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import PhoneCode from '../forms/PhoneCode';

    export default {

        components: {
            Field,
            Form,
            ErrorMessage,
            PhoneCode
        },

        props: { 
            locatingNumber: {
                type: String,
                default: '',
            },
            notifyCode:{
                type: String,
                default: '',
            }
        },

        data(){
            return {
                notificationNumber: '',
                currentText: '',
                countyCode: '',
                predefinedTexts: [
                    this.$translate('locate_modal_hello_a'),
                    this.$translate('locate_modal_hello_b'),
                    this.$translate('locate_modal_hello_c'),
                    this.$translate('locate_modal_hello_d'),
                    this.$translate('locate_modal_hello_e'),
                    this.$translate('locate_modal_hello_f'),
                ],
            };
        },

        computed: {
            locatingNumberCode(){
                return this.$store.phone_code;
            }
        },

        mounted(){
            
        },

        methods: {
            showModal(){
                
            },
            onSubmit(e){
                axios.post('/user/locateRequest', {
                    country_code: this.$store.phone_code,
                    mobile_number: this.locatingNumber,
                    notify_country_code: this.countryCode,
                    notify_phone_number: this.notificationNumber,
                    text: this.currentText
                }).then(res => {
                    if(res.data.result == 'success'){
                        let message = `${this.$translate('locate_modal_sent_on')} + ${this.locatingNumberCode} ${this.locatingNumber} ${this.$translate('locate_modal_sent_success')}`;
                        this.$modals.hide('locate-modal');
                        this.$modals.notification(message);
                        return;
                    }        
                    this.$modals.notification(res.data.message);
                })
            },
            updateCountryCode(code){
                this.countryCode = code;
            }
        }    
    }

</script>
